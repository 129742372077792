import React, {useEffect, useState} from 'react'
import {DataGrid} from "@mui/x-data-grid";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import {DeleteOutline} from "@material-ui/icons";
import {LinearProgress, LinearProgressProps} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ApiDetails} from "../../../dummyData";
import Backdrop from "@mui/material/Backdrop";
import {BarWave} from "react-cssfx-loading";


const useStyles = makeStyles({
    root: {
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
            fontSize: 16,
            color: "darkblue",
            backgroundColor: "rgb(245,245,245)",
        },
    },
});

export default function Properties(props) {

    let [backDrop, setBackDrop] = useState(
        <Backdrop
            sx={{
                backgroundColor: "rgba(0, 31, 63, 0.7)",
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
            onClick={null}
        >
            <BarWave color="#FFA500" width="100px" height="50px" duration="3s"/>
        </Backdrop>
    );

    function LinearProgressWithLabel(
        props: LinearProgressProps & { value: number }
    ) {
        return (
            <Box sx={{display: "flex", alignItems: "center"}}>
                <Box sx={{width: "100%", mr: 1}}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{minWidth: 35}}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }

    const [open, setOpen] = useState(false);
    const [propertyDetails, setPropertyDetails] = useState({
        id: "",
        name: "",
    });

    const [properties, setProperties] = useState([])
    const propertyColumns = [
        {
            field: "property_name",
            headerName: "Property Name",
            minWidth: 150,
            flex: 1,
            renderCell: (params) => {
                return <div className="userListUser">{params.row.property_name}</div>;
            },
        },
        {
            field: "location",
            headerName: "Location",
            minWidth: 200,
            flex: 1,
        },
        {
            field: "total_property_units",
            headerName: "Total Units",
            minWidth: 130,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "occupancy",
            headerName: "Occupancy",
            minWidth: 180,
            flex: 1,
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <Box sx={{width: "100%"}}>
                        <LinearProgressWithLabel
                            value={parseInt(params.row.occupancy.replace("%", "").trim())}
                        />
                    </Box>
                );
            },
        },
        {
            field: "expected_revenue",
            headerName: "Expected Rent",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",

            renderCell: (params) => {
                return (
                    <div className="userListUser">
                        {params.row.expected_revenue.toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Actions    ",
            minWidth: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <div
                        className="userListUser"
                        style={{
                            width: "80%",
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <DeleteOutline
                            style={{
                                color: "red",
                            }}
                            className="userListDelete"
                            onClick={() => {
                                setPropertyDetails({
                                    id: params.row.id,
                                    name: params.row.property_name,
                                });
                                setOpen(true);
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    const getProperties = () => {
        //Fetch landlord details
        fetch(
            ApiDetails +
            "pegasus/visionary/authorization/admin/get/landlord/properties",
            {
                method: "POST",
                body: JSON.stringify({
                    landlord_id: props.landlord_id,
                }),
            }
        )
            .then(async (response) => {
                let details = await response.json();
                if (response.status === 200) {
                    setProperties(details);

                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getProperties()
        setBackDrop(<></>);
    }, []);

    return (
        <>
            {backDrop}
            <DataGrid
                initialState={{
                    sorting: {
                        sortModel: [{field: "id", sort: "asc"}],
                    },
                }}
                className={useStyles.root}
                rows={properties}
                disableSelectionOnClick
                rowHeight={45}
                columns={propertyColumns}
                pageSize={10}
                rowsPerPageOptions={[10]}
            />

        </>
    )
}