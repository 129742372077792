export var loginState = false;
export const KEY = "MarkKieru@55";
export const RECAPTCHA = "6Lc_PkMkAAAAAAxgKyNYSKZVkGnwAVupNHfpsIgJ";

//Live API
// SERVER_IP_ADDRESS = 172.105.244.19;
// API_PORT_NUMBER = :8080
// export const ApiDetails = "/api/";
// export const ApiDetails = "https://172.105.244.19:8080/";

// export const ApiDetails = "https://api.pegasus-pms.co.ke/api/";
export const ApiDetails = "https://api.pegasus-pms.co.ke/api/";
export const SKY_AUTH_ApiDetails = "https://api.pegasus-pms.co.ke/sky_api";

//Local API
// export const ApiDetails = "//0.0.0.0:8080/";
