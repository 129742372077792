import React, { useEffect, useState } from "react";
import "./settings.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ApiDetails } from "../../dummyData";
import AccountDetails from "./settingsTabs/AccountDetails";
import Security from "./settingsTabs/Security";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export default function Settings(props) {
  const [landlordDetails, setLandlordDetails] = useState();
  useEffect(() => {
    fetch(ApiDetails + "pegasus/visionary/dashboard/get/landlord/details", {
      method: "POST",
      body: JSON.stringify({
        landlord_id: parseFloat(localStorage.getItem("id")),
      }),
    })
      .then(async (response) => {
        let data = await response.json();
        if (response.status === 200) {
          // console.log(data);
          setLandlordDetails(data);
          setPage(
            <AccountDetails
              landlordDetails={data}
              refresh={(value) => {
                props.refresh(value);
              }}
              snackBar={props.snackBar}
            />
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
    props.topBar("");
  }, []);

  //Loading Screen
  const [backDrop, setBackDrop] = useState(
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
      onClick={null}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  const [value, setValue] = React.useState("account");

  const handleChange = (event, newValue) => {
    if (newValue === "security") {
      setPage(
        <Security landlordDetails={landlordDetails} snackBar={props.snackBar} />
      );
    } else if (newValue === "account") {
      setPage(
        <AccountDetails
          landlordDetails={landlordDetails}
          snackBar={props.snackBar}
        />
      );
    }
    setValue(newValue);
  };

  const [page, setPage] = useState();
  return (
    <div
      className="settings"
      style={{
        height: "calc(100vh - 100px)",
        padding: "20px",
        display: "flex",
      }}
    >
      <div
        className="right"
        style={{
          flex: "100%",
          overflow: "auto",
        }}
      >
        <div
          style={{
            color: "#9A005A",
            letterSpacing: 2,
            textTransform: "uppercase",
            fontFamily: "'DM Serif Display', serif",
            display: "flex",
            alignItems: "center",
          }}
        >
          <h2>Settings</h2>
        </div>

        <Box sx={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab value="account" label="Account settings" />
            <Tab value="security" label="Security settings" />
          </Tabs>
        </Box>

        {page}
      </div>
    </div>
  );
}
