import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ProSidebarProvider} from "react-pro-sidebar";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";
// Somewhere in your `index.ts`:
import {setChonkyDefaults} from 'chonky';

const {ChonkyIconFA} = require('chonky-icon-fontawesome');

// Somewhere in your `index.js` or `index.ts`:
setChonkyDefaults({iconComponent: ChonkyIconFA});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ProSidebarProvider>
        <DevSupport ComponentPreviews={ComponentPreviews}
                    useInitialHook={useInitial}
        >
            <App/>
        </DevSupport>
    </ProSidebarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
