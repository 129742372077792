import React, {useEffect, useState} from "react";
import "./TopBar.css";
import {Logout, NotificationsNone, Settings,} from "@mui/icons-material";
import {Avatar, Menu, MenuItem, useMediaQuery} from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {Link} from "react-router-dom";
import adminProfile from "../../Assets/react-logo-no-background.svg";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import {ApiDetails} from "../../dummyData";

export default function TopBar(props) {
    const [forceRefresh, setForceRefresh] = useState(false);
    const matches = useMediaQuery("(max-width:700px)");
    const location = window.location;

    const [isAgent, setIsAgent] = useState(false);

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');

        if (storedUsername === undefined || storedUsername === null || storedUsername === "") {
        } else {
            setName(localStorage.getItem('username'));

        }

    }, [forceRefresh, matches]);

    const [accountImage] = useState("");
    const [initialName, setInitialName] = useState("new user");
    const [landlordImage, setLandlordImage] = useState("");

    const [name, setName] = React.useState(initialName);

    const getInitials = function (string) {
        // setName(string)

        // console.log("String : " + string)
        let names = string.split(" "),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    //New fetch photo
    useEffect(() => {
        try {

            // let pic = JSON.parse(originalText)[16][1];
            let pic;

            fetch(ApiDetails + "pegasus/visionary/authorization/admin/photo", {
                method: "POST",
                body: JSON.stringify({
                    landlord_id: parseInt(localStorage.getItem('id')),
                }),
            })
                .then(async (response) => {
                    let data = await response.json();
                    if (response.status === 200) {
                        pic = data.landlord_photo;
                        // setLandlordImage(pic);

                        if (pic.base64 !== "No File Found") {
                            setLandlordImage("data:image/jpeg;base64," + pic.base64);
                        }
                        // console.log(pic);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });


        } catch (e) {
            // console.log(e);
        }
    }, []);


    const [breadCrumbPath, setBreadCrumbPath] = useState(
        <Breadcrumbs aria-label="breadcrumb">
            <Link
                underline="hover"
                // sx={{display: 'flex', alignItems: 'center'}}
                style={{textDecoration: 'none', color: "#a1ffe3"}}
                color="white"
                href="/landlords"
            >
                <HomeIcon sx={{mr: 0.5}} fontSize="inherit"/>
                Landlords
            </Link>
            {/*<Typography*/}
            {/*    sx={{display: 'flex', alignItems: 'center'}}*/}
            {/*    color="text.primary"*/}
            {/*>*/}
            {/*    <GrainIcon sx={{mr: 0.5}} fontSize="inherit"/>*/}
            {/*    Breadcrumb*/}
            {/*</Typography>*/}
        </Breadcrumbs>
    )

    const setPathName = () => {

        for (let i = 0; i < 3; i++) {
            const newLink = (
                <Link key={`link-${i}`} underline="hover" style={{textDecoration: 'none', color: "#a1ffe3"}}
                      href={`/additional-link-${i}`}>
                    {/* Customize the content of the additional links as needed */}
                    Additional Link {i + 1}
                </Link>
            );

            // Concatenate the new link to the existing breadCrumbPath
            setBreadCrumbPath((prevBreadCrumbPath) => (
                <>
                    {prevBreadCrumbPath.props.children}
                    {newLink}
                </>
            ));
        }

    }

    const setInitials = (name) => {

        if (name === '') {
            setForceRefresh(!forceRefresh)
            return;
        }
        let initials = getInitials(name)
        // console.log(initials)
        setForceRefresh()
        // setPathName(location)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = (event) => {
        // 👇️ toggle class on click
        window.location.href = "/";
        localStorage.clear();
    };

    function handleBreadCrumbClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    return {
        setInitials,
        render: (
            <div className="topbar">
                <div className="topbarWrapper">
                    <div
                        style={{
                            display: "flex",
                        }}
                        className="topLeft">
                        <div>
              <span
                  style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      color: "#6DFACD",
                  }}
              >
                Welcome, {name.toUpperCase()}
              </span>
                        </div>
                    </div>
                    <div

                        style={{
                            paddingLeft: "50px",
                            margin: "auto"
                        }}
                        role="presentation" onClick={handleBreadCrumbClick}>
                        {breadCrumbPath}
                    </div>
                    <div className="topRight">
                        <div
                            className="topbarIconContainer"
                            style={{
                                display: "none",
                            }}
                        >
                            <NotificationsNone style={{color: "white", fontSize: "25px"}}/>
                            {/*<span className="topIconBadge">{notificationsNumber}</span>*/}
                        </div>

                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                            }}
                        >
                            <Tooltip title="Account settings">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    aria-controls={open ? "account-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                >

                                    {landlordImage === "" ? (
                                        <Avatar
                                            style={{
                                                width: 45,
                                                height: 45,
                                            }}
                                            alt="Admin profile picture"
                                            src={adminProfile}
                                        />
                                    ) : (<Avatar
                                        style={{
                                            width: 45,
                                            height: 45,
                                        }}
                                        alt="Admin profile picture"
                                        src={landlordImage}
                                    />)}

                                </IconButton>
                            </Tooltip>
                        </Box>

                        <Menu
                            anchorEl={anchorEl}
                            // id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            transformOrigin={{horizontal: "right", vertical: "top"}}
                            anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                        >
                            <MenuItem
                                style={{
                                    display: "none"
                                }}
                            >

                                <ListItemIcon
                                    style={{
                                        marginRight: "10px",
                                    }}
                                >
                                    <Avatar
                                        style={{
                                            marginRight: "10px",
                                        }}
                                    />
                                </ListItemIcon>
                                User management

                            </MenuItem>
                            <MenuItem>
                                <Link
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    className="linkItem"
                                    to={"/settings"}
                                >
                                    <ListItemIcon
                                        style={{
                                            marginRight: "10px",
                                        }}
                                    >
                                        <Avatar
                                            style={{
                                                marginRight: "10px",
                                            }}
                                        />
                                    </ListItemIcon>
                                    My account
                                </Link>
                            </MenuItem>
                            <Divider/>
                            <MenuItem>
                                <Link className="linkItem" to={"/settings"}>
                                    <ListItemIcon
                                        style={{
                                            marginRight: "10px",
                                        }}
                                    >
                                        <Settings fontSize="small"/>
                                    </ListItemIcon>
                                    Settings
                                </Link>
                            </MenuItem>

                            <MenuItem>
                                {/*<Link onClick={logout} className="linkItem" to={"/"}>*/}
                                <div onClick={logout}>
                                    <ListItemIcon
                                        style={{
                                            marginRight: "10px",
                                        }}
                                    >
                                        <Logout fontSize="small"/>
                                    </ListItemIcon>
                                    Logout
                                </div>
                                {/*</Link>*/}
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
        ),
    };
}