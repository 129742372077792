import React, {useEffect, useState} from "react";
import "./App.css";
import {loginState} from "./dummyData";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./Pages/Home/Home";
import TestPage from "./Pages/TestPage";
import Tenant from "./Pages/tenant/Tenant";
import TopBar from "./Components/TopBar/TopBar";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {NotificationContainer,} from "react-notifications";
import "react-notifications/lib/notifications.css";
import LoginPage from "./Pages/Login/Login";
import User from "./Pages/user/User";
import TestSidebar from "./Components/SideBar/SideBar";
import Backdrop from "@mui/material/Backdrop";
import {BarWave} from "react-cssfx-loading";
import {Toaster} from "react-hot-toast";
import {MyFileBrowser} from "./Pages/Backup/Backup";
import Settings from "./Pages/settings/Settings";
import SystemLogs from "./Pages/SysLogs/SystemLogs";
import {Button, Fab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import Dialog from "@mui/material/Dialog";
import {Rating} from "@mui/lab";
import {TextArea} from "@instructure/ui-text-area";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function App() {
    const logout = () => {
        // 👇️ toggle class on click
        window.location.href = "/";
        localStorage.clear();
    };

    const [open, setOpen] = React.useState(loginState);

    //Loading Screen
    let [backDrop, setBackDrop] = useState(
        <Backdrop
            sx={{
                backgroundColor: "rgba(0, 31, 63, 0.7)",
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
            onClick={null}
        >
            {/*CLICHE*/}
            <BarWave color="#FFA500" width="100px" height="50px" duration="3s"/>

            {/*Looks disorderly*/}
            {/*<Hypnosis color="#FFA500" width="100px" height="70px" duration="3s" />*/}

            {/*<SpinStretch color="#FFA500" width="100px" height="70px" duration="3s" />*/}
            {/*<SpinStretch color="#FF5733" width="100px" height="70px" duration="3s" />*/}
            {/*<FadingDots color="#4BA6C5" width="100px" height="70px" duration="3s" />*/}
            {/*<FlippingSquare color="#4BA6C5" width="100px" height="70px" duration="3s" />*/}
            {/*<TwinSpin color="#4BA6C5" width="100px" height="70px" duration="3s" />*/}
            {/*<FillingBottle color="#4BA6C5" width="100px" height="70px" duration="3s" />*/}
        </Backdrop>
        // <></>
    );

    const [feedback, setFeedback] = useState("Kindly provide more information ...");
    const [rating, setRating] = useState(0);
    const [loading, SetLoading] = useState(false);
    const [feedbackModal, setFeedbackModal] = useState(false);

    const [value, setValue] = useState("");

    const sendFeedback = () => {
        console.log("Rating : " + rating);
    };

    const [message, setMessage] = React.useState("");

    const [severity, setSeverity] = React.useState();

    const [isLoggedIn, setLogin] = React.useState(
        !(
            window.location.pathname === "/" || window.location.pathname === "/signup"
        )
    );

    const [connectionMade, setConnectionMade] = React.useState(false);

    const changeLogin = (name) => {
        // console.log("String : " + name)
        setLogin(true);
        setInitials(name);
        // setForceRefresh()
        // updateViewTopBar(true);
    };

    const handleClick = (message) => {
        setMessage(message.text);
        setSeverity(message.type);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        setBackDrop(<></>);
    }, []);

    const {render, setInitials} = TopBar();
    const {sideBar} = TestSidebar({yourProp: setFeedbackModal});

    const [, setInactive] = useState(false);

    // Define a function to reset the inactivity timer
    const resetInactivityTimer = () => {
        setInactive(false);
    };

    // Set up event listeners to track user interactions
    useEffect(() => {
        const inactivityTimeout = setTimeout(() => {
            setInactive(true);
            // console.log("Tenant is inactive")
            logout();
        }, 3600000); // 1 hour of inactivity

        // Add event listeners to reset the timer on user interactions
        window.addEventListener("mousemove", resetInactivityTimer);
        window.addEventListener("keydown", resetInactivityTimer);

        // Clean up the event listeners when the component unmounts
        return () => {
            clearTimeout(inactivityTimeout);
            window.removeEventListener("mousemove", resetInactivityTimer);
            window.removeEventListener("keydown", resetInactivityTimer);
        };
    }, []);

    const [screenshots, setScreenshots] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    const MAX_IMAGE_SIZE_BYTES = 5 * 1024 * 1024; // 5 MB (adjust as needed)

    const handleDrop = async (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];

        if (file) {
            if (file.size <= MAX_IMAGE_SIZE_BYTES) {

                const reader = new FileReader();
                reader.onload = (e) => {
                    setScreenshots([...screenshots, e.target.result]);
                };
                reader.readAsDataURL(file);
            } else {
                alert("Selected image is too large. Please choose a smaller image.");
            }
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleMouseEnter = () => {
        // Add your fade effect logic or any other styling changes on mouse enter
    };

    const handleMouseLeave = () => {
        // Add your fade effect logic or any other styling changes on mouse leave
    };

    return (
        // <ConfirmProvider>
        <div>
            <Dialog
                fullWidth
                maxWidth="md"
                open={feedbackModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            // color: "#FF74D2",
                            color: "#002521",
                            // color: "black",
                            letterSpacing: 2,
                            marginBottom: "10px",
                            textTransform: "uppercase",
                            fontFamily: "'DM Serif Display', serif",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <h3>Feedback</h3>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div
                            style={{
                                display: "flex",
                            }}
                        >
                            <div
                                style={{
                                    // color: "#9A005A",
                                    color: "#002521",
                                    // color: "black",
                                    letterSpacing: 2,
                                    marginRight: "20px",
                                    // textTransform: "uppercase",
                                    fontSize: "22px",
                                    fontFamily: "'DM Serif Display', serif",
                                }}
                            >
                                <h5>How did we do? </h5>
                            </div>

                            <Stack spacing={1}>
                                <Rating
                                    size={"medium"}
                                    name="half-rating"
                                    defaultValue={rating}
                                    precision={0.5}
                                    style={{
                                        color: "#FFD700",
                                    }}
                                    // color: "#FF74D2"
                                    onChange={(e) => setRating(e.target.value)}
                                />
                            </Stack>
                        </div>

                        <div
                            style={{
                                color: "#9A005A",
                                letterSpacing: 2,
                                marginTop: "30px",
                                marginBottom: "10px",
                                textTransform: "uppercase",
                                fontFamily: "'DM Serif Display', serif",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {/*<h5>Kindly provide more information</h5>*/}
                        </div>
                        <TextArea
                            value={feedback}
                            onChange={(e) => {
                                setFeedback(e.target.value);
                            }}
                            height={"200px"}
                            maxHeight="50rem"
                        />

                        <div
                            style={{
                                border: "2px dashed green",
                                padding: "20px",
                                textAlign: "center",
                                marginTop: "10px"
                            }}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => {
                                //Open select image dialog
                            }}
                        >
                            <p>
                                Drop the files here...
                            </p>
                        </div>
                        <div

                            style={{
                                display: "flex",
                                marginTop: "10px",
                                overflowX:"scroll",
                            }}>
                            {screenshots.map((image) => {
                                // console.log(image)
                                return (
                                    <div>
                                        <div
                                            style={{
                                                width: "80px",
                                                height: "80px",
                                                borderRadius: "20%",
                                                overflow: "hidden",
                                                marginRight: "10px",
                                                border: "2px dashed #ccc",
                                                position: "relative",
                                            }}
                                            onClick={() => {
                                                setModalOpen(true)
                                            }}
                                        >

                                            <div>
                                                <img
                                                    style={{
                                                        width: "80px",
                                                        height: "80px",
                                                    }}
                                                    alt="Admin profile "
                                                    src={image}
                                                />
                                            </div>
                                        </div>

                                        {modalOpen && (
                                            <div
                                                style={{
                                                    position: "fixed",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    zIndex: 1000,
                                                    transition: "opacity 0.3s ease-in-out",
                                                }}
                                                onClick={() => setModalOpen(false)}
                                            >
                                                <div
                                                    style={{
                                                        opacity: modalOpen ? 1 : 0,
                                                    }}
                                                    className={"feedbackPictureModal"}
                                                >
                                                    <img
                                                        src={image}
                                                        alt="Server"
                                                        style={{width: "100%", height: "100%", objectFit: "cover"}}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setFeedbackModal(false);
                        }}
                    >
                        <span style={{color: "red"}}>Disagree</span>
                    </Button>
                    <LoadingButton
                        size="small"
                        onClick={() => sendFeedback()}
                        loading={loading}
                        startIcon={<SendIcon/>}
                        style={{color: "green"}}
                        loadingPosition="start"
                    >
                        Agree
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <div>
                <Toaster/>
            </div>
            {/*{inactive && <p>Tenant is inactive</p>}*/}
            <NotificationContainer/>
            <Stack spacing={2} sx={{width: "100%"}}>
                <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        severity={severity}
                        sx={{width: "100%"}}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>
            {backDrop}
            <div className="App">
                <div className="cont">
                    <Router>
                        {isLoggedIn ? <>{sideBar}</> : <></>}
                        <div
                            style={{
                                flex: 4,
                                maxWidth: "calc(100vw - 80px)",
                                minWidth: "calc(100vw - 800px)",
                                backgroundColor: "whitesmoke",
                            }}
                        >
                            {isLoggedIn ? <> {render}</> : <></>}

                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        <LoginPage
                                            snackBar={(message) => {
                                                handleClick(message);
                                            }}
                                            fn={(value) => changeLogin(value)}
                                        />
                                    }
                                />
                                <Route
                                    path="/landlords"
                                    element={
                                        <Home
                                            loginState={isLoggedIn}
                                            topBar={(value) => changeLogin(value)}
                                            snackBar={(message) => handleClick(message)}
                                        />
                                    }
                                />

                                <Route
                                    path="/backup"
                                    element={
                                        <MyFileBrowser
                                            snackBar={(message) => handleClick(message)}
                                            topBar={(value) => changeLogin(value)}
                                        />
                                        //         <FolderUpload
                                        //         snackBar={(message) => handleClick(message)}
                                        //     topBar={(value) => changeLogin(value)}
                                        //
                                        // />
                                    }
                                />
                                <Route
                                    path="/landlord/:id"
                                    element={
                                        <User snackBar={(message) => handleClick(message)}/>
                                    }
                                />

                                <Route
                                    path="/tenant/:id"
                                    element={
                                        <Tenant snackBar={(message) => handleClick(message)}/>
                                    }
                                />

                                <Route
                                    path="/test"
                                    element={
                                        <TestPage
                                            topBar={(value) => changeLogin(value)}
                                            snackBar={(message) => handleClick(message)}
                                        />
                                    }
                                />

                                <Route
                                    path="/logs"
                                    element={
                                        <SystemLogs
                                            topBar={(value) => changeLogin(value)}
                                            snackBar={(message) => handleClick(message)}
                                        />
                                    }
                                />

                                <Route
                                    path="/settings"
                                    element={
                                        <Settings
                                            topBar={(value) => changeLogin(value)}
                                            snackBar={(message) => handleClick(message)}
                                        />
                                    }
                                />
                            </Routes>
                        </div>
                    </Router>
                </div>
            </div>

            <div style={{display: "none"}} className="bottom-right-div">
                {/*<p>This is the bottom right div</p>*/}
                <Fab color="primary" aria-label="add">
                    <AddIcon/>
                </Fab>
            </div>
        </div>
        // </ConfirmProvider>
    );
}

export default App;
