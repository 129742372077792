import React, {useEffect, useState} from 'react'
import {DataGrid} from "@mui/x-data-grid";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import {CheckCircleOutlineOutlined, DeleteOutline} from "@material-ui/icons";
import {Button, LinearProgress, LinearProgressProps, Switch} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ApiDetails} from "../../../dummyData";
import {PaymentsOutlined, WarningAmberOutlined} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import {BarWave} from "react-cssfx-loading";


const useStyles = makeStyles({
    root: {
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
            fontSize: 16,
            color: "darkblue",
            backgroundColor: "rgb(245,245,245)",
        },
    },
});

export default function Tenants(props) {
    const navigate = useNavigate();


    let [backDrop, setBackDrop] = useState(
        <Backdrop
            sx={{
                backgroundColor: "rgba(0, 31, 63, 0.7)",
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
            onClick={null}
        >
            <BarWave color="#FFA500" width="100px" height="50px" duration="3s"/>
        </Backdrop>
    );

    function LinearProgressWithLabel(
        props: LinearProgressProps & { value: number }
    ) {
        return (
            <Box sx={{display: "flex", alignItems: "center"}}>
                <Box sx={{width: "100%", mr: 1}}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{minWidth: 35}}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }

    const label = {inputProps: {"aria-label": "Switch demo"}};

    const [open, setOpen] = useState(false);
    let [tenantInfo, setTenantInfo] = React.useState({
        tenant_id: "",
        name: "",
        last_name: "",
        unit: "",
        property_id: "",
        account_number: "",
    });

    const [tenants, setTenants] = useState([])
    const tenantColumns = [
        {
            field: "user",
            headerName: "Name",
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            display: "flex",
                        }}
                        className="userListUser"
                    >
                        <div
                            style={{
                                margin: "0 5px",
                            }}
                        >
                            {params.row.state === "NOTICE" ? (
                                <WarningAmberOutlined
                                    style={{
                                        color: "#F0972A",
                                    }}
                                />
                            ) : (
                                <CheckCircleOutlineOutlined
                                    style={{
                                        color: "#57AF51",
                                    }}
                                />
                            )}
                        </div>
                        <div>
                            {params.row.first_name} {params.row.last_name}
                        </div>
                    </div>
                );
            },
        },
        {
            field: "property_name",
            align: "left",
            headerName: "Property name",
            minWidth: 100,
            flex: 1,
            sortOrder: "ASC",
        },
        {
            field: "house_number",
            align: "center",
            headerName: "HSE NO",
            minWidth: 100,
            flex: 1,
            sortOrder: "ASC",
        },
        {
            field: "phone_number",
            align: "center",
            headerName: "Phone Number",
            minWidth: 150,
            flex: 1,
        },
        {
            field: "rent",
            headerName: "Rent",
            minWidth: 120,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div className="userListAction">
                        {parseInt(
                            params.row.rent.toString().replace(",", "")
                        ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </div>
                );
            },
        },
        {
            field: "",
            headerName: "Pay",
            minWidth: 80,
            flex: 1,
            headerAlign: "center",
            align: "right",
            renderCell: (params) => {
                return (
                    <>
                        <div
                            style={{
                                width: "100px",
                                display: "flex",
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                        >
                            <PaymentsOutlined
                                style={{
                                    scale: "1.5",
                                    color: "green",
                                }}
                                onClick={() => {
                                    props.setTenantInfo({
                                        id: params.row.tenant_id,
                                        name: params.row.first_name,
                                        last_name: params.row.last_name,
                                        account_number: params.row.account_number,
                                    });
                                    props.transactionModal();
                                }}
                            />
                        </div>
                    </>
                );
            },
        },
        {
            field: "login_successful",
            headerName: "Logged in?",
            flex: 1,
            minWidth: 100,
            width: 100,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div className="userListAction">
                        {params.row.login_successful ? (
                            <Switch disabled={true} {...label} defaultChecked/>
                        ) : (
                            <Switch disabled={true} {...label} />
                        )}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Actions",
            minWidth: 180,
            flex: 1,
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                        }}
                        className="userListAction"
                    >
                        <Button
                            onClick={() => {
                                navigate(
                                    "/tenant/" +
                                    params.row.first_name +
                                    "_" +
                                    params.row.last_name,
                                    {
                                        state: {
                                            tenant_id: params.row.id,
                                            property_id: params.row.property_id,
                                            landlord_id: props.landlord_id,
                                        },
                                    }
                                );
                            }}
                            variant="outlined"
                        >
                            Details
                        </Button>

                        <DeleteOutline
                            style={{
                                color: "red",
                                paddingLeft: "10px",
                            }}
                            className="userListDelete"
                            onClick={() => {
                                props.setTenantInfo({
                                    tenant_id: params.row.tenant_id,
                                    name: params.row.first_name,
                                    last_name: params.row.last_name,
                                    unit: params.row.house_number,
                                    property_id: params.row.property_id,
                                });
                                setOpen(true);
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    const getTenants = () => {
        //Fetch landlord details
        fetch(
            ApiDetails +
            "pegasus/visionary/authorization/admin/get/landlord/tenants",
            {
                method: "POST",
                body: JSON.stringify({
                    landlord_id: props.landlord_id,
                }),
            }
        )
            .then(async (response) => {
                let details = await response.json();
                if (response.status === 200) {
                    setTenants(details);

                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getTenants()
        setBackDrop(<></>);
    }, []);

    return (
        <DataGrid
            initialState={{
                sorting: {
                    sortModel: [{field: "id", sort: "asc"}],
                },
            }}
            className={useStyles.root}
            rows={tenants}
            disableSelectionOnClick
            rowHeight={45}
            columns={tenantColumns}
            pageSize={10}
            rowsPerPageOptions={[10]}
        />
    )
}