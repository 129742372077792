import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import "./Login.css";
import {ApiDetails, SKY_AUTH_ApiDetails,} from "../../dummyData";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import LoadingButton from "@mui/lab/LoadingButton";
import logo from "../../Assets/react-logo-no-background.svg";
import wave from "../../Assets/login_background_3.mp4";
import {TextField} from "@material-ui/core";
import {Login} from "@mui/icons-material";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {LockOutlined, ThumbDown, ThumbUp, VisibilityOffOutlined, VisibilityOutlined,} from "@material-ui/icons";
import InputAdornment from "@mui/material/InputAdornment";
import toast from "react-hot-toast";
import {ReCAPTCHA} from "react-google-recaptcha";
import Tooltip from "@mui/material/Tooltip";

export default function LoginPage(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [loadingAuthentication, setLoadingAuthentication] =
        React.useState(false);
    const [proceed, setProceed] = React.useState(false);

    const confirmAuthCode = async (event, email, id) => {
        event.preventDefault();
        setLoadingAuthentication(true);
        let code = document.getElementById("auth_code").value;
        if (code === "") {
            setLoadingAuthentication(false);


            toast.error("Code cannot be empty", {
                style: {
                    border: '1px solid #red   ',
                    padding: '16px',
                    color: 'red',
                },
                icon: <ThumbDown/>,
                iconTheme: {
                    primary: '#713200',
                    secondary: '#FFFAEE',
                },
            });
            return;
        }
        let body = {
            auth_code: code,
            identifier_type: "MAIL",
            identifier: email,
            program_id: parseInt("100009"),
        };
        fetch(SKY_AUTH_ApiDetails + "/sky-auth/confirmation", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                // let data = await response.json();
                // console.log(data);
                if (response.status === 200) {

                    navigate("/landlords", {
                        state: {
                            landlord_id: id,
                        },
                    });

                    props.refresh(true);
                    toast.success("Login successful", {
                        style: {
                            border: '1px solid #green   ',
                            padding: '16px',
                            color: 'green',
                        },
                        icon: <ThumbUp/>,
                        iconTheme: {
                            primary: '#713200',
                            secondary: '#FFFAEE',
                        },
                    });
                    props.fn();
                } else {
                    toast.error("Invalid code provided", {
                        style: {
                            border: '1px solid #red   ',
                            padding: '16px',
                            color: 'red',
                        },
                        icon: <ThumbDown/>,
                        iconTheme: {
                            primary: '#713200',
                            secondary: '#FFFAEE',
                        },
                    });
                    setLoadingAuthentication(false);
                }
            })
            .catch((err) => {
                console.log(err);


                toast.error("Something Went Wrong. Try Again Later", {
                    style: {
                        border: '1px solid #red   ',
                        padding: '16px',
                        color: 'red',
                    },
                    icon: <ThumbDown/>,
                    iconTheme: {
                        primary: '#713200',
                        secondary: '#FFFAEE',
                    },
                });

                setLoadingAuthentication(false);
            });
    };

    const onSubmit = (values) => {
        setLoading(true);
        values = {...values};
        fetch(ApiDetails + "pegasus/visionary/authorization/admin/login", {
            method: "POST",
            body: JSON.stringify(values),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    let adminDetails = new Map(Object.entries(data));

                    localStorage.setItem('username', data.names);
                    localStorage.setItem('id', data.landlord_id);

                    // if (false) {
                    if (false) {
                        //Create SSE Connection

                        try {
                            await fetchEventSource(
                                SKY_AUTH_ApiDetails + "/sky-auth/confirmation/sse/100009",
                                {
                                    method: "GET",
                                    headers: {
                                        Accept: "text/event-stream",
                                    },
                                    onopen(res) {
                                        if (res.ok && res.status === 200) {
                                            // console.log("Connection made ", res);

                                            //Get Qr Code
                                            //program_id,identifier,identifier_type
                                            const body = {
                                                identifier_type: "MAIL",
                                                identifier: values.email_address,
                                                program_id: parseInt("100009"),
                                            };
                                            fetch(
                                                SKY_AUTH_ApiDetails +
                                                "/sky-auth/authorization/biometric/add",
                                                {
                                                    method: "POST",
                                                    body: JSON.stringify(body),
                                                }
                                            )
                                                .then(async (response) => {
                                                    let data = await response.json();
                                                    if (response.status === 200) {
                                                        // console.log(data);
                                                        let base_64_image = new Map(
                                                            Object.entries(data)
                                                        ).get("Base64 QR image");

                                                        setLoginPage(
                                                            <div className="Authentication">
                                                                <div
                                                                    style={{
                                                                        height: "80vh",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignContent: "center",
                                                                        textAlign: "center",
                                                                        backgroundColor: "white",
                                                                        margin: "0 auto",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            flexDirection: "column",
                                                                        }}
                                                                        className="col-1-auth"
                                                                    >
                                                                        <form id="form" className="flex flex-col">
                                                                            <label
                                                                                htmlFor="login_email"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "start",
                                                                                }}
                                                                            >
                                                                                <h3> Sky Auth Code</h3>
                                                                            </label>
                                                                            <input type="number" id="auth_code"/>

                                                                            {/*<button*/}
                                                                            {/*  className="btn"*/}
                                                                            {/*  onClick={(event) => {*/}
                                                                            {/*    confirmAuthCode(*/}
                                                                            {/*      event,*/}
                                                                            {/*      values.email_address,*/}
                                                                            {/*      data*/}
                                                                            {/*    );*/}
                                                                            {/*  }}*/}
                                                                            {/*>*/}
                                                                            {/*  Verify Code*/}
                                                                            {/*</button>*/}

                                                                            <LoadingButton
                                                                                endIcon={<LockOutlined/>}
                                                                                loading={loadingAuthentication}
                                                                                loadingPosition="end"
                                                                                variant="contained"
                                                                                onClick={(event) => {
                                                                                    confirmAuthCode(
                                                                                        event,
                                                                                        values.email_address,
                                                                                        data,
                                                                                        new Map(Object.entries(data)).get("landlord_id")
                                                                                    );
                                                                                }}
                                                                                // style={{
                                                                                //     width: "100%",
                                                                                //     marginTop: "10px",
                                                                                //     marginBottom: "10px",
                                                                                // }}
                                                                                className="btn"
                                                                            >
                                                                                Verify code
                                                                            </LoadingButton>

                                                                            <br/>
                                                                            <span
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                        Kindly enter auth code or scan the QR
                                        code before the timer expires
                                      </span>
                                                                        </form>
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            flexDirection: "column",
                                                                        }}
                                                                        className="col-2-auth"
                                                                    >
                                                                        {/*CountDown*/}

                                                                        <img
                                                                            style={{
                                                                                height: "calc(100% - 30px)",
                                                                                width: "100%",
                                                                            }}
                                                                            src={
                                                                                "data:image/png;base64," + base_64_image
                                                                            }
                                                                            alt="Red dot"
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                        setProceed(true);
                                                    } else {
                                                        toast.error("Invalid Code Provided.", {
                                                            style: {
                                                                border: '1px solid #red   ',
                                                                padding: '16px',
                                                                color: 'red',
                                                            },
                                                            icon: <ThumbDown/>,
                                                            iconTheme: {
                                                                primary: '#713200',
                                                                secondary: '#FFFAEE',
                                                            },
                                                        });
                                                        setLoading(false);
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.log(err);
                                                    toast.error("Something Went Wrong. Try Again Later", {
                                                        style: {
                                                            border: '1px solid #red   ',
                                                            padding: '16px',
                                                            color: 'red',
                                                        },
                                                        icon: <ThumbDown/>,
                                                        iconTheme: {
                                                            primary: '#713200',
                                                            secondary: '#FFFAEE',
                                                        },
                                                    });
                                                });
                                        } else if (
                                            res.status >= 400 &&
                                            res.status < 500 &&
                                            res.status !== 429
                                        ) {
                                            console.log("Client side error ", res);
                                        }
                                    },
                                    onmessage() {
                                        // let notifications = Array.from(JSON.parse(event.data));

                                        navigate("/landlords", {
                                            state: {
                                                landlord_id: new Map(Object.entries(data)).get("landlord_id"),
                                            },
                                        });

                                        props.refresh(true);

                                        toast.success("Login successful", {
                                            style: {
                                                border: '1px solid #green   ',
                                                padding: '16px',
                                                color: 'green',
                                            },
                                            icon: <ThumbUp/>,
                                            iconTheme: {
                                                primary: '#713200',
                                                secondary: '#FFFAEE',
                                            },
                                        });
                                        props.fn();
                                        // console.log(event.data);
                                    },
                                    onclose() {
                                        console.log(
                                            "Connection closed by the server retrying ... "
                                        );
                                    },
                                    onerror(err) {
                                        console.log("There was an error from server", err);

                                        // handleClose();
                                        // getUserDetails(data);
                                    },
                                }
                            );
                        } catch (e) {
                            console.log(e);
                        }


                    } else {

                        // props.refresh(true);
                        props.fn(adminDetails.get("names"));
                        toast.success('Login successful.', {
                            style: {
                                border: '1px solid #green   ',
                                padding: '16px',
                                color: 'green',
                            },
                            icon: <ThumbUp/>,
                            iconTheme: {
                                primary: '#713200',
                                secondary: '#FFFAEE',
                            },
                        });
                        navigate("/landlords", {
                            state: {
                                landlord_id: adminDetails.get("landlord_id"),
                            },
                        });

                    }
                } else if (response.status === 401) {
                    setLoading(false);

                    toast.error("Maximum concurrent logins reached", {
                        style: {
                            border: '1px solid #red   ',
                            padding: '16px',
                            color: 'red',
                        },
                        icon: <ThumbDown/>,
                        iconTheme: {
                            primary: '#713200',
                            secondary: '#FFFAEE',
                        },
                    });
                } else if (response.status === 400) {

                    toast.error("Invalid Credentials Provided.", {
                        style: {
                            border: '1px solid #red   ',
                            padding: '16px',
                            color: 'red',
                        },
                        icon: <ThumbDown/>,
                        iconTheme: {
                            primary: '#713200',
                            secondary: '#FFFAEE',
                        },
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);


                toast.success("Something Went Wrong. Try Again Later", {
                    style: {
                        border: '1px solid #red   ',
                        padding: '16px',
                        color: 'red',
                    },
                    icon: <ThumbDown/>,
                    iconTheme: {
                        primary: '#713200',
                        secondary: '#FFFAEE',
                    },
                });
            });
    };

    localStorage.clear();
    const [loginPage, setLoginPage] = useState();

    const initialValues = {
        email_address: "",
        password: "",
    };
    const validationSchema = Yup.object().shape({
        email_address: Yup.string()
            .email('Invalid email address')
            .required('Email address is required'),
        password: Yup.string()
            .required('Password is required'),
    });
    const [type, setType] = useState("password");
    const [icon, setIcon] = useState(<VisibilityOutlined/>);

    const [isForgotPasswordHovered, setForgotPasswordHovered] = useState(false);
    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: -1,
                overflow: 'hidden',
            }}
        >
            <video
                autoPlay
                loop
                muted
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    position: "absolute",
                }}
            >
                <source src={wave} type="video/mp4"/>

            </video>
            {/* Your other content goes here */}
            <div style={{position: 'relative', zIndex: 1}}>

                <div className={"fullPage"}>
                    <div
                        style={{
                            height: "70vh",
                            width: "70vw",
                            borderRadius: "20px",
                            overflow: "hidden"
                        }}
                    >
                        {proceed ? (
                            <>{loginPage}</>
                        ) : (
                            <div className="register">
                                <div className="col-1-auth">
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-evenly",
                                            alignItems: "center",
                                            position: "relative"
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "250px",
                                                height: "150px",
                                            }}
                                            src={logo}
                                            alt=""
                                        />
                                        <div>
                                            <h2>Log In</h2>
                                            <span
                                                style={{
                                                    color: "#adb5bd",
                                                }}
                                            >
                            Welcome back!
                          </span>
                                        </div>
                                    </div>

                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={onSubmit}
                                    >
                                        {(props) => (
                                            <Form noValidate>
                                                <Field
                                                    as={TextField}
                                                    type={'email'}
                                                    name="email_address"
                                                    label="Email address"
                                                    variant="outlined"
                                                    InputLabelProps={{shrink: true}}
                                                    error={props.errors.email_address && props.touched.email_address}
                                                    helperText={<ErrorMessage name="email_address"/>}
                                                    required
                                                    style={{
                                                        width: '100%',
                                                        marginTop: '10px',
                                                        borderRadius: '8px',
                                                    }}
                                                />
                                                <Field
                                                    as={TextField}
                                                    type={type}
                                                    name="password"
                                                    label="Password"
                                                    variant="outlined"
                                                    required
                                                    InputLabelProps={{shrink: true}}
                                                    error={props.errors.password && props.touched.password}
                                                    helperText={<ErrorMessage name="password"/>}
                                                    style={{
                                                        width: '100%',
                                                        marginTop: '10px',
                                                        cursor: 'pointer',
                                                        borderRadius: '8px',
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment
                                                                style={{
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() => {
                                                                    if (type === 'password') {
                                                                        setType('text');
                                                                        setIcon(<VisibilityOffOutlined

                                                                        />);
                                                                    } else {
                                                                        setType('password');
                                                                        setIcon(<VisibilityOutlined

                                                                        />);
                                                                    }
                                                                }}
                                                                position="end"
                                                            >
                                                                {icon}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />

                                                {/* Forgot password div with hover effect */}
                                                <Tooltip title="Kindly contact support">
                                                    <div
                                                        onMouseEnter={() => setForgotPasswordHovered(true)}
                                                        onMouseLeave={() => setForgotPasswordHovered(false)}
                                                        style={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'right',
                                                            marginTop: '10px',
                                                            marginBottom: '15px',
                                                            cursor: 'pointer',
                                                            color: isForgotPasswordHovered ? '#00f' : '#8696B9',
                                                            transition: 'color 0.3s', // Add transition for a smooth effect
                                                        }}
                                                    >
                                                        Forgot password?
                                                    </div>
                                                </Tooltip>

                                                <ReCAPTCHA
                                                    sitekey="YOUR_RECAPTCHA_SITE_KEY"
                                                    onChange={(value) => {
                                                        // Handle reCAPTCHA response if needed
                                                    }}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        marginTop: '10px',
                                                    }}
                                                />

                                                <LoadingButton
                                                    type={'submit'}
                                                    endIcon={<Login/>}
                                                    loading={loading}
                                                    loadingPosition="end"
                                                    variant="contained"
                                                    style={{
                                                        width: '100%',
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                        borderRadius: '8px',
                                                    }}
                                                >
                                                    Log In
                                                </LoadingButton>
                                            </Form>
                                        )}
                                    </Formik>

                                </div>

                                <div className="col-2-auth">
                                    <img src={require("../../Assets/Admin_profile-removebg-preview.png")} alt=""/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </div>
    );
}