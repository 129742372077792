import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Treebeard } from "react-treebeard";
import { ApiDetails } from "../../dummyData";
import { Button } from "@mui/material";

const FolderUpload = (props) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileTree, setFileTree] = useState({});

  const onDrop = useCallback((acceptedFiles) => {
    // Specify the target folder for all uploaded files
    const targetFolder = "uploads";

    // Filter out files larger than 30MB
    const filesWithinLimit = acceptedFiles.filter(
      (file) => file.size <= 30 * 1024 * 1024
    ); // 30MB limit

    // Update the state with the list of uploaded files
    setUploadedFiles(filesWithinLimit);

    // Build a file tree structure with the target folder
    const newFileTree = buildFileTree(filesWithinLimit, targetFolder);
    // console.log(newFileTree instanceof File)

    setFileTree(newFileTree);
  }, []);

  // ...

  const buildFileTree = (files, targetFolder) => {
    const fileTree = {
      name: targetFolder,
      toggled: true,
      children: [],
    };

    files.forEach((file) => {
      const pathParts = `${targetFolder}/${file.path}`.split("/");
      let currentNode = fileTree;

      pathParts.forEach((part) => {
        if (!currentNode.children) {
          currentNode.children = [];
        }

        const existingNode = currentNode.children.find(
          (node) => node.name === part
        );
        if (existingNode) {
          currentNode = existingNode;
        } else {
          const newNode = {
            name: part,
            toggled: true,
            children: [],
          };
          currentNode.children.push(newNode);
          currentNode = newNode;
        }
      });
    });

    return fileTree;
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      multiple: true,
      maxSize: 30 * 1024 * 1024, // 30MB limit
    });

  const onToggle = (node) => {
    node.toggled = !node.toggled;
    setFileTree({ ...fileTree });
  };

  const [landlordId, setLandlordId] = useState();

  useEffect(() => {
    props.topBar("");
    setLandlordId(localStorage.getItem("id"));
  }, []);

  const uploadFiles = () => {
    // console.log("id" + landlordId)
    let values = {
      landlord_id: landlordId,
    };
    let formData = new FormData();

    // Append JSON string as a field named 'body'
    formData.append("body", JSON.stringify(values));

    // Append each uploaded file individually
    uploadedFiles.forEach((file, index) => {
      formData.append(`file${index + 1}`, file);
    });

    // Log FormData to see its structure
    //         console.log(formData);

    fetch(ApiDetails + "pegasus/visionary/files/backup", {
      method: "POST",
      body: formData,
    })
      .then(async (response) => {
        let data = await response.json();
        // console.log(data);
        if (response.status === 200) {
          //Fetch tenant arrears
          setUploadedFiles([]);
          setFileTree({});
          props.snackBar({
            text: "FolderUpload successful",
            type: "success",
          });
          props.refresh();
        } else {
          props.snackBar({
            text: "FolderUpload failed.",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        props.snackBar({
          text: "Something Went Wrong. Call Customer Care For Assistance.",
          type: "error",
        });
      });
  };

  return (
    <div>
      <div
        {...getRootProps()}
        style={{
          border: `2px dashed ${
            isDragReject ? "red" : isDragActive ? "green" : "#ccc"
          }`,
          padding: "20px",
          textAlign: "center",
        }}
      >
        <input {...getInputProps()} />
        <p>
          {isDragActive
            ? "Drop the files here..."
            : isDragReject
            ? "File type not accepted, please drop only files up to 30MB"
            : "Drag & drop files here, or click to select"}
        </p>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div
          style={{
            width: "40vw",
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            paddingBottom: "20px",
          }}
        >
          <h4>Uploaded Files:</h4>

          {uploadedFiles.length === 0 ? (
            <></>
          ) : (
            <Button
              onClick={() => {
                uploadFiles();
              }}
              variant="outlined"
            >
              Upload files
            </Button>
          )}
        </div>
        <ul>
          {uploadedFiles.map((file, index) => (
            <li key={index}>{file.path}</li>
          ))}
        </ul>
      </div>
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <h4>File Tree:</h4>
        <Treebeard data={fileTree} onToggle={onToggle} />
      </div>
    </div>
  );
};

export default FolderUpload;
