import React, { useEffect, useState } from "react";

const TestPage = (props) => {
  useEffect(() => {
    props.topBar("");
  }, []);

  const [userDetails, setUserDetails] = useState({
    name: "John Doe",
    phoneNumber: "123-456-7890",
    emailAddress: "john.doe@example.com",
    // You can replace the placeholder image URL with the actual image URL.
    imageUrl: "https://placekitten.com/200/200",
  });

  return (
    <div style={styles.container}>
      <div style={styles.profileContainer}>
        <img
          src={userDetails.imageUrl}
          alt="User"
          style={styles.profileImage}
        />
      </div>
      <div style={styles.detailsContainer}>
        <h2>{userDetails.name}</h2>
        <p>
          <strong>Phone:</strong> {userDetails.phoneNumber}
        </p>
        <p>
          <strong>Email:</strong> {userDetails.emailAddress}
        </p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "50px",
  },
  profileContainer: {
    marginBottom: "20px",
  },
  profileImage: {
    width: "200px",
    height: "200px",
    borderRadius: "50%",
  },
  detailsContainer: {
    textAlign: "center",
  },
};

export default TestPage;
