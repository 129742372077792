import React, {useEffect, useState} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import {LinearProgress, LinearProgressProps} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ApiDetails} from "../../../dummyData";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Backdrop from "@mui/material/Backdrop";
import {BarWave} from "react-cssfx-loading";
import {TextField} from "@material-ui/core";
import * as Yup from 'yup';
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'

makeStyles({
    root: {
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
            fontSize: 16,
            color: "darkblue",
            backgroundColor: "rgb(245,245,245)",
        },
    },
});
export default function Settings(props) {

    let [backDrop, setBackDrop] = useState(
        <Backdrop
            sx={{
                backgroundColor: "rgba(0, 31, 63, 0.7)",
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
            onClick={null}
        >
            <BarWave color="#FFA500" width="100px" height="50px" duration="3s"/>
        </Backdrop>
    );

    function LinearProgressWithLabel(
        props: LinearProgressProps & { value: number }
    ) {
        return (
            <Box sx={{display: "flex", alignItems: "center"}}>
                <Box sx={{width: "100%", mr: 1}}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{minWidth: 35}}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }

    const updateLandlordDetails = (values) => {

        setLoading(true)
        let body = {...values, two_factor_enabled: two_factor_enabled, two_fa_set: two_fa_set}
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/authorization/admin/update/landlords", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                let data = await response.json();

                if (response.status === 200) {
                    props.snackBar({
                        text: "Details Updated Successful",
                        type: "success",
                    });
                    setBackDrop(
                        <></>
                    );
                } else {
                    props.snackBar({
                        text: "Kindly check your details then resubmit the form",
                        type: "error",
                    });
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
                setLoading(false)
            });
    };

    const myObject = Object.fromEntries(props.initialValues);

    const [two_fa_set, setTwo_fa_set] = useState(Object.fromEntries(props.initialValues).two_fa_set)
    const [two_factor_enabled, setTwo_factor_enabled] = useState(Object.fromEntries(props.initialValues).two_factor_enabled)

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('First Name is required'),
        last_name: Yup.string().required('Last Name is required'),
        phone_number: Yup.number()
            .required('Phone Number is required')
            .test(
                'is-kenyan-phone',
                'Phone Number must be a valid Kenyan phone number',
                value => /^(2547|07|2541|01)?\d{8}$/.test(value)
            ),
        email_address: Yup.string()
            .email('Invalid email address')
            .required('Email Address is required'),
        two_factor_enabled: Yup.string().required('Two Factor Enabled is required').oneOf(['yes', 'no', 'YES', 'NO'], 'Two Factor Enabled must be either "yes" or "no"'),
        two_fa_set: Yup.string().required('Two FA Set is required').oneOf(['yes', 'no', 'YES', 'NO'], 'Two FA Set must be either "yes" or "no"'),
        free_months: Yup.number().required('Free Months is required'),
        subscription_percentage: Yup.number().required('Subscription Percentage is required').min(1, 'Subscription Percentage cannot be 0'),
    });

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setBackDrop(<></>);
    }, []);

    return (
        <div>
            {backDrop}
            <Formik
                initialValues={myObject}
                validationSchema={validationSchema}
                onSubmit={updateLandlordDetails}
            >
                {(props) => (
                    <Form noValidate>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Field
                                as={TextField}
                                name="first_name"
                                label="First Name"
                                variant="outlined"
                                error={
                                    props.errors.first_name && props.touched.first_name
                                }
                                helperText={<ErrorMessage name="first_name"/>}
                                required
                                style={{marginTop: "10px", width: "49%"}}
                            />
                            <Field
                                as={TextField}
                                name="last_name"
                                label="Last Name"
                                error={props.errors.last_name && props.touched.last_name}
                                helperText={<ErrorMessage name="last_name"/>}
                                required
                                variant="outlined"
                                style={{marginTop: "10px", width: "49%"}}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Field
                                as={TextField}
                                type={"number"}
                                name="phone_number"
                                label="Phone Number"
                                error={
                                    props.errors.phone_number && props.touched.phone_number
                                }
                                helperText={<ErrorMessage name="phone_number"/>}
                                required
                                variant="outlined"
                                style={{marginTop: "10px", width: "49%"}}
                            />
                            <Field
                                as={TextField}
                                // disabled
                                name="subscription_percentage"
                                label="subscription %"
                                error={
                                    props.errors.subscription_percentage &&
                                    props.touched.subscription_percentage
                                }
                                helperText={
                                    <ErrorMessage name="subscription_percentage"/>
                                }
                                variant="outlined"
                                style={{marginTop: "10px", width: "49%"}}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <FormControl
                                required={true}
                                style={{marginTop: "10px", width: "49%"}}
                            >
                                <InputLabel id="demo-simple-select-label">2FA Set</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={two_fa_set}
                                    label="2FA Set"
                                    onChange={(event) => setTwo_fa_set(event.target.value)}
                                >
                                    <MenuItem value={'YES'}>YES</MenuItem>
                                    <MenuItem value={'NO'}>NO</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl
                                required={true}
                                style={{marginTop: "10px", width: "49%"}}
                            >
                                <InputLabel id="demo-simple-select-label">2FA Enabled</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={two_factor_enabled}
                                    label="2FA Enabled"
                                    onChange={(event) => setTwo_factor_enabled(event.target.value)}
                                >
                                    <MenuItem value={'YES'}>YES</MenuItem>
                                    <MenuItem value={'NO'}>NO</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Field
                                as={TextField}
                                type={"number"}
                                name="free_months"
                                label="Free Months"
                                error={props.errors.free_months && props.touched.free_months}
                                helperText={<ErrorMessage name="free_months"/>}

                                variant="outlined"
                                style={{marginTop: "10px", width: "49%"}}
                            />

                            <Field
                                as={TextField}
                                name="date_created"
                                label="Date Joined"
                                variant="outlined"
                                disabled={true}
                                style={{marginTop: "10px", width: "49%"}}
                            />
                        </div>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "end",
                                // marginTop: "10px",
                                marginBottom: "20px",
                            }}
                        >
                            <LoadingButton
                                type={"submit"}
                                size="small"
                                loading={loading}
                                startIcon={<SendIcon/>}
                                style={{color: "green"}}
                                loadingPosition="start"
                            >
                                Submit
                            </LoadingButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
