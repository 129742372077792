import React, {useEffect, useState} from "react";
import "./Home.css";
import {makeStyles} from "@material-ui/core/styles";
import Backdrop from "@mui/material/Backdrop";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {useLocation, useNavigate} from "react-router-dom";
import {BarWave} from "react-cssfx-loading";
import {ApiDetails} from "../../dummyData";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    DeleteOutline,
    EmailOutlined,
    Error,
    LocalPhoneOutlined,
    Search,
    ThumbUp,
    Visibility,
    VisibilityOff
} from "@material-ui/icons";
import {Button, FormControl, InputLabel, OutlinedInput} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import SendIcon from "@mui/icons-material/Send";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import toast from "react-hot-toast";
import {TextField} from "@material-ui/core";
import {CalendarMonthOutlined, FilterAltOutlined, Person2Outlined} from "@mui/icons-material";


const Transition = React.forwardRef(function Transition(props: TransitionProps & {
    children: React.ReactElement<any, any>,
}, ref: React.Ref<unknown>) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    root: {
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
            fontSize: 16,
            color: "darkblue",
            backgroundColor: "rgb(245,245,245)",
        },
    },
});
export default function Home(props) {
    const navigate = useNavigate();

    let classes = useStyles;
    // Use useLocation hook to get the current location object
    const location = useLocation();

    // Access the state variable landlord_id
    const landlordId = location.state && location.state.landlord_id;

    //Loading Screen
    let [backDrop, setBackDrop] = useState(<Backdrop
            sx={{backgroundColor: 'rgba(0, 31, 63, 0.7)', color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
            onClick={null}
        >
            <BarWave color="#FFA500" width="100px" height="50px" duration="3s"/>
        </Backdrop>
        // <></>
    );

    const [landlords, setLandlords] = useState([])
    const [searchResults, setSearchResults] = useState([])

    const [landlordInfo, setLandlordInfo] = useState({})

    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState()
    const [email, setEmail] = useState()


    const [showPassword, handleClickShowPassword] = useState(false)

    // Modal
    const [deleteModal, setDeleteModal] = useState(false)
    const [authenticationModal, setAuthenticationModal] = useState(false)
    const getLandlords = () => {
        fetch(ApiDetails + "pegasus/visionary/authorization/admin/landlords", {
            method: "POST",
            body: {},
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    // console.log(data)
                    setLandlords(data.filter((d) => d.id !== landlordId))
                    setSearchResults(data.filter((d) => d.id !== landlordId))
                    setBackDrop(<></>)
                } else {
                    toast.error('Something went wrong. Try again later.', {
                        style: {
                            border: '1px solid #red   ',
                            padding: '16px',
                            color: 'red',
                        },
                        icon: <Error/>,
                        iconTheme: {
                            primary: '#713200',
                            secondary: '#FFFAEE',
                        },
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    }

    useEffect(() => {
        //Fetch all Landlords
        getLandlords()
        props.topBar('')
    }, []);

    const logout = (event) => {
        // 👇️ toggle class on click
        window.location.href = "/";
        localStorage.clear();
        props.fn();
    };

    const searchProperty = (category, e) => {
        if (category === "name") {
            setSearchResults(
                Array.from(landlords).filter((item) =>
                    (item.first_name.toLowerCase() + " " + item.first_name.toLowerCase()).includes(e.target.value.toLowerCase())
                )
            );
        } else if (category === "mail") {
            setSearchResults(
                Array.from(landlords).filter((item) =>
                    (item.email_address.toLowerCase()).includes(e.target.value.toLowerCase())
                )
            );
        } else if (category === "number") {
            setSearchResults(
                Array.from(landlords).filter((item) =>
                    (item.phone_number.toString().toLowerCase()).includes(e.target.value.toLowerCase())
                )
            );
        }
    };

    const handleClose = () => {
        setDeleteModal(false)
    }
    const handleDelete = () => {

        //Delete landlord
        fetch(ApiDetails + "pegasus/visionary/authorization/admin/delete/account", {
            method: "POST",
            body: JSON.stringify({
                admin_id: landlordId,
                landlord_id: landlordInfo.landlord_id,
                password: password,
                email_address: email,
            }),
        })
            .then(async (response) => {
                if (response.status === 200) {


                    toast.success('Landlord account deleted Successfully.', {
                        style: {
                            border: '1px solid #green   ',
                            padding: '16px',
                            color: 'green',
                        },
                        icon: <ThumbUp/>,
                        iconTheme: {
                            primary: '#713200',
                            secondary: '#FFFAEE',
                        },
                    });
                    getLandlords()
                } else if (response.status === 401) {
                    toast.error('Invalid credentials.', {
                        style: {
                            border: '1px solid #red   ',
                            padding: '16px',
                            color: 'red',
                        },
                        icon: <Error/>,
                        iconTheme: {
                            primary: '#713200',
                            secondary: '#FFFAEE',
                        },
                    });
                } else if (response.status === 400) {
                    props.snackBar({
                        text: "Invalid Credentials Provided.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    }

    let columns;

    columns = [
        {
            field: "",
            headerName: (
                <div
                    style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Person2Outlined
                        style={{
                            paddingRight: "10px",
                            color: "#00B0BF"

                        }}
                    />
                    &nbsp;Name
                </div>
            ),
            // width: 200,
            minWidth: 200,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            display: "flex",
                        }}
                        className="userListUser"
                    >
                        <div>
                            {params.row.first_name} {params.row.last_name}
                        </div>
                    </div>
                );
            },
        },
        {
            field: "email_address",
            headerName: (
                <div
                    style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center"
                    }}
                >
                    <EmailOutlined
                        style={{
                            paddingRight: "10px",
                            color: "#00B0BF"
                        }}
                    />
                    &nbsp;Email
                </div>
            ),
            // width: 200,
            minWidth: 100,
            flex: 1,
        },
        {
            field: "phone_number",
            headerName: (
                <div
                    style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center"
                    }}
                >
                    <LocalPhoneOutlined
                        style={{
                            paddingRight: "10px",
                            color: "#00B0BF"

                        }}
                    />
                    &nbsp;Mobile Number
                </div>
            ),
            // width: 200,
            minWidth: 100,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            display: "flex",
                        }}
                        className="userListUser"
                    >
                        <div>
                            {params.row.phone_number.toLocaleString('en-KE', {
                                style: 'decimal',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </div>
                    </div>
                );
            },
        },
        {
            field: "date_created",
            headerName: (
                <div
                    style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center"
                    }}
                >
                    <CalendarMonthOutlined
                        style={{
                            paddingRight: "10px",
                            color: "#00B0BF"

                        }}
                    />
                    &nbsp;Date Created
                </div>
            ),
            // width: 200,
            minWidth: 100,
            flex: 1,
        },
        {
            field: "action",
            headerName: "Actions",
            minWidth: 180,
            flex: 1,
            // width: 180,
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <>

                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                alignContent: "center"
                            }}
                            className="userListAction">
                            <Button
                                onClick={() => {

                                    navigate("/landlord/" + params.row.first_name + "_" + params.row.last_name, {
                                        state: {
                                            landlord_id: params.row.id,
                                        },
                                    });
                                }}
                                variant="outlined"
                            >
                                Details
                            </Button>

                            <DeleteOutline
                                style={{
                                    color: "red"
                                }}
                                className="userListDelete"
                                onClick={() => {
                                    setLandlordInfo({
                                        landlord_id: params.row.id,
                                        name: params.row.first_name,
                                        last_name: params.row.last_name,
                                    });
                                    setDeleteModal(true)
                                }}
                            />
                        </div>

                    </>
                );
            },
        },
    ];

    return (<>

        <Dialog
            open={deleteModal}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle style={{color: "red"}}>
                Delete {landlordInfo.name}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Typography>
                        Deleting a user will completely remove them and their respective
                        documents from the system.
                    </Typography>
                    <br/>
                    <Typography>
                        Kindly note that this action is irreversible
                    </Typography>
                    <br/>
                    <Typography>
                        Are you sure you want to delete this user ?
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    <span style={{color: "red"}}>Disagree</span>
                </Button>
                <LoadingButton
                    size="small"
                    onClick={() => setAuthenticationModal(true)}
                    loading={loading}
                    startIcon={<SendIcon/>}
                    style={{color: "green"}}
                    loadingPosition="start"
                >
                    Agree
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog
            open={authenticationModal}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle style={{color: "red"}}>
                Enter password
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Typography>
                        Deleting a user will completely remove them and their respective
                        documents from the system.
                    </Typography>

                    <FormControl sx={{m: 1, width: '100%'}} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={'text'}
                            onChange={(event) => {
                                setEmail(event.target.value)
                            }}
                            label="Email"
                        />
                    </FormControl>

                    <FormControl sx={{m: 1, width: '100%'}} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            onChange={(event) => {
                                setPassword(event.target.value)
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setAuthenticationModal(false)}>
                    <span style={{color: "red"}}>Disagree</span>
                </Button>
                <LoadingButton
                    size="small"
                    onClick={() => {
                        handleDelete();
                        setDeleteModal(false)
                        setAuthenticationModal(false)
                    }}
                    loading={loading}
                    startIcon={<SendIcon/>}
                    style={{color: "green"}}
                    loadingPosition="start"
                >
                    Agree
                </LoadingButton>
            </DialogActions>
        </Dialog>
        {backDrop}

        <div
            style={{
                padding: "10px"
            }}>
            <div
                style={{
                    height: "80px",
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center"
                }}
            >

                <Typography
                    style={{
                        paddingRight: "20px",
                        fontSize: "18px",
                        display: "flex",
                        alignItems: "center"

                    }}
                >
                    <Search
                        style={{
                            color: "blue",
                            fontSize: "25px",
                            paddingRight: "5px"
                        }}
                    /> Search and <FilterAltOutlined
                    style={{
                        color: "blue",
                        fontSize: "25px",
                        paddingRight: "5px",
                        paddingLeft: "5px"
                    }}
                /> Filter
                </Typography>
                <TextField
                    style={{
                        paddingRight: "15px"
                    }}
                    id="outlined-basic"
                    label="Search by name  ... "
                    variant="outlined"
                    type={"text"}
                    onChange={(e) => {
                        searchProperty("name", e);
                    }}
                    autoComplete="new-password"
                />
                <TextField
                    style={{
                        paddingRight: "15px"
                    }}
                    id="outlined-basic"
                    label="Search by email  ... "
                    variant="outlined"
                    type={"email"}
                    onChange={(e) => {
                        searchProperty("mail", e);
                    }}
                    autoComplete="new-password"
                />
                <TextField
                    style={{
                        paddingRight: "15px"
                    }}
                    id="outlined-basic"
                    label="Search by number  ... "
                    variant="outlined"
                    type={"number"}
                    onChange={(e) => {
                        searchProperty("number", e);
                    }}
                    autoComplete="new-password"
                />
            </div>

            <div
                style={{
                    height: " calc(100vh - 170px)"
                }}>
                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [{field: "date_created", sort: "asc"}],
                        },
                    }}
                    className={classes.root}
                    rows={searchResults}
                    disableSelectionOnClick
                    rowHeight={45}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                />
            </div>
        </div>
    </>)
}