import React, { useEffect, useState } from "react";
import "./SystemLogs.css";
import { DataGrid } from "@mui/x-data-grid";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { CalendarMonthOutlined, Person2Outlined } from "@mui/icons-material";
import { EmailOutlined, LocalPhoneOutlined } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
      fontSize: 16,
      color: "darkblue",
      backgroundColor: "rgb(245,245,245)",
    },
  },
});

export default function SystemLogs(props) {
  let classes = useStyles;

  const [logs, setLogs] = useState([]);
  const [searchResults, setSearchResults] = useState([
    {
      id: 1,
      type: "ERROR",
      title: "Null pointer exception",
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque mollis lacinia lorem et gravida. Pellentesque ac blandit tortor. Donec finibus sit amet lacus quis congue. Suspendisse porttitor mi eu ullamcorper fermentum. Nullam est ligula, tempor ac maximus ut, iaculis eget libero. Quisque eget leo est. Curabitur blandit, tellus ac blandit scelerisque, neque risus facilisis leo, vel tincidunt magna ligula vel nibh. Praesent malesuada ac elit non efficitur. Nam sagittis nibh nec risus aliquet finibus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut pulvinar, turpis a ullamcorper maximus, arcu nisl placerat ligula, eget viverra nisl massa at mi. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi porttitor enim at metus pulvinar rutrum. Integer suscipit non ex ut consectetur. Nulla viverra ut sapien a molestie.\n" +
        "\n" +
        "Nullam rhoncus ornare lectus eu varius. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Proin ut elit rhoncus, iaculis massa quis, dictum risus. Morbi sed suscipit sapien, quis consequat orci. Maecenas vel tortor eu orci vehicula interdum sit amet ac mauris. Praesent mollis velit non malesuada laoreet. Nullam augue risus, tincidunt vitae laoreet aliquet, porttitor ac lacus. Duis sollicitudin sapien faucibus iaculis malesuada. Donec vel suscipit urna. Proin interdum leo nec dolor auctor vehicula. Nullam id enim sodales, tempus quam et, congue lectus.",
      priority: "HIGH",
      date_created: new Date(),
    },
  ]);

  useEffect(() => {
    //Fetch system logs
    props.topBar("");
  }, []);

  let sysLogColumns = [
    {
      field: "type",
      headerName: (
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Person2Outlined
            style={{
              paddingRight: "10px",
              color: "#00B0BF",
            }}
          />
          &nbsp;Log Type
        </div>
      ),
      // width: 200,
      minWidth: 50,
      flex: 1,
    },
    {
      field: "title",
      headerName: (
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <EmailOutlined
            style={{
              paddingRight: "10px",
              color: "#00B0BF",
            }}
          />
          &nbsp;Title
        </div>
      ),
      // width: 200,
      minWidth: 600,
      flex: 1,
    },
    {
      field: "priority",
      headerName: (
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <LocalPhoneOutlined
            style={{
              paddingRight: "10px",
              color: "#00B0BF",
            }}
          />
          &nbsp;Priority
        </div>
      ),
      // width: 200,
      minWidth: 50,
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
            }}
            className="userListUser"
          >
            <div>{params.row.priority}</div>
          </div>
        );
      },
    },
    {
      field: "date_created",
      headerName: (
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <CalendarMonthOutlined
            style={{
              paddingRight: "10px",
              color: "#00B0BF",
            }}
          />
          &nbsp;Date Created
        </div>
      ),
      // width: 200,
      minWidth: 80,
      flex: 1,
    },
  ];

  return (
    <div
      style={{
        height: "calc(100vh - 110px)",
        padding: "20px",
      }}
    >
      <div>Show System Logs</div>

      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "date_created", sort: "asc" }],
          },
        }}
        className={classes.root}
        rows={searchResults}
        disableSelectionOnClick
        rowHeight={45}
        columns={sysLogColumns}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </div>
  );
}
