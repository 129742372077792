import React, { useState } from "react";
import "../settings.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { updatePasswordSchema } from "../../../Components/schemas/Schemas";
import Switch from "@mui/material/Switch";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { ApiDetails, SKY_AUTH_ApiDetails } from "../../../dummyData";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import CryptoJS from "crypto-js";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Security(props) {
  const linkProgramToSkyAuth = () => {
    let body = {
      program_id: 100009,
      identifier: props.landlordDetails.email_address,
      identifier_type: "MAIL",
    };

    // console.log(body);
    fetch(SKY_AUTH_ApiDetails + "/sky-auth/programs/generate/link/token", {
      method: "POST",
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        let data = await response.json();
        // console.log(response.status);
        if (response.status === 200) {
          setToken(data);
          setTokenModal(true);
        } else {
          props.snackBar({
            text: "Something Went Wrong. Try Again Later",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        props.snackBar({
          text: "Something Went Wrong. Try Again Later",
          type: "error",
        });
      });
  };
  const getUserDetails = (userDetails) => {
    fetch(ApiDetails + "pegasus/visionary/dashboard/get/landlord/details", {
      method: "POST",
      body: JSON.stringify(userDetails),
    })
      .then(async (response) => {
        let data = await response.json();
        if (response.status === 200) {
          const details = new Map(Object.entries(data));

          localStorage.setItem(
            "username",
            data.first_name + " " + data.last_name
          );
          localStorage.setItem("id", data.landlord_id);

          props.snackBar({
            text: "Details Updated Successful",
            type: "success",
          });
          // window.location.reload(false);
        } else {
          props.snackBar({ text: "Failed Fetching Details", type: "error" });
        }
      })
      .catch((err) => console.log(err));
  };

  let initialValues = {
    old_password: "",
    new_password: "",
  };
  const [loading, setLoading] = useState(false);
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [tokenModal, setTokenModal] = useState(false);
  const [token, setToken] = useState("");
  const handleClose = () => setTokenModal(false);

  const [viewOldPassword, setViewOldPassword] = React.useState(false);
  const [viewNewPassword, setViewNewPassword] = React.useState(false);
  const [viewConfirmNewPassword, setViewConfirmNewPassword] =
    React.useState(false);

  const handleClickShowPassword = () => {
    setViewOldPassword(!viewOldPassword);
  };
  const handleClickShowNewPassword = () => {
    setViewNewPassword(!viewNewPassword);
  };
  const handleClickShowConfirmNewPassword = () => {
    setViewConfirmNewPassword(!viewConfirmNewPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const updatePassword = (values) => {
    setLoading(true);
    let body = {
      old_password: values.old_password,
      new_password: values.new_password,
      email_address: props.landlordDetails.email_address,
    };
    // console.log(body)

    // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
    fetch(ApiDetails + "pegasus/visionary/authorization/update/password", {
      method: "POST",
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        if (response.status === 200) {
          props.snackBar({
            text: "Password Updated Successfully.",
            type: "success",
          });
          setLoading(false);

          localStorage.clear();
          window.location.href = "/";
        } else if (response.status === 401) {
          props.snackBar({
            text: "Incorrect Old Password Provided.",
            type: "error",
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        props.snackBar({
          text: "Something Went Wrong. Try Again Later",
          type: "error",
        });
        setLoading(false);
      });
    setLoading(false);
  };

  return (
    <div>
      <Dialog
        open={tokenModal}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              color: "#3C92D5",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Sky Auth Link Token
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "10px",
          }}
        >
          <Typography>
            Dear{" "}
            {props.landlordDetails.first_name +
              " " +
              props.landlordDetails.last_name}
            {", "}please use this code to link your account to the sky auth app:{" "}
            <span
              style={{
                color: "#3C92D5",
              }}
            >
              {token}
            </span>
          </Typography>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginTop: "20px",
            }}
          >
            <Button onClick={handleClose}>
              <span style={{ color: "green" }}>Close</span>
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <br />
      <span
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          color: "#398DD2",
        }}
      >
        Change password
      </span>

      <Formik
        initialValues={initialValues}
        validationSchema={updatePasswordSchema}
        onSubmit={updatePassword}
      >
        {(props) => (
          <Form noValidate>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  flex: "20%",
                }}
              >
                old Password
              </span>
              <br />
              <Field
                as={OutlinedInput}
                id="outlined-adornment-password"
                type={viewOldPassword ? "text" : "password"}
                name="old_password"
                style={{ marginTop: "10px", flex: "80%" }}
                error={props.errors.old_password && props.touched.old_password}
                helperText={<ErrorMessage name="old_password" />}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {viewOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Old Password"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  flex: "20%",
                }}
              >
                New Password
              </span>
              <br />
              <Field
                as={OutlinedInput}
                id="outlined-adornment-password"
                type={viewNewPassword ? "text" : "password"}
                name="new_password"
                style={{ marginTop: "10px", flex: "80%" }}
                label="New Password"
                error={props.errors.new_password && props.touched.new_password}
                helperText={<ErrorMessage name="new_password" />}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {viewNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  flex: "20%",
                }}
              >
                Confirm Password
              </span>
              <br />
              <Field
                as={OutlinedInput}
                // id="outlined-adornment-password"
                type={viewConfirmNewPassword ? "text" : "password"}
                name="confirm_new_password"
                label="Confirm Password"
                error={
                  props.errors.confirm_new_password &&
                  props.touched.confirm_new_password
                }
                helperText={<ErrorMessage name="confirm_new_password" />}
                required
                style={{ marginTop: "10px", flex: "80%" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {viewConfirmNewPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>

            <br />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: "20px",
              }}
            >
              <LoadingButton
                size="small"
                type="submit"
                loading={loading}
                startIcon={<SendIcon />}
                style={{ color: "green" }}
                loadingPosition="start"
              >
                Submit
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <div>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              color: "#398DD2",
            }}
          >
            Enable 2FA
          </span>
          <br />
          <Typography
            style={{
              color: "#959EAD",
              width: "80%",
            }}
          >
            You will be required to provide a 6 digit auth code obtained from
            SKY AUTH app to complete the login process
          </Typography>
        </div>
        {props.landlordDetails.two_factor_enabled === "YES" ? (
          <Switch
            onChange={() => {
              let body = {
                two_factor_enabled: "NO",
                landlord_id: parseFloat(localStorage.getItem("id")),
              };
              // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
              fetch(ApiDetails + "pegasus/visionary/authorization/2fa", {
                method: "POST",
                body: JSON.stringify(body),
              })
                .then(async (response) => {
                  if (response.status === 200) {
                    if (props.landlordDetails.two_fa_set === "NO") {
                      linkProgramToSkyAuth();
                    }
                    getUserDetails({
                      landlord_id: parseFloat(localStorage.getItem("id")),
                    });
                  } else {
                    props.snackBar({
                      text: "Something Went Wrong. Try Again Later",
                      type: "error",
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                  });
                });
            }}
            {...label}
            defaultChecked
          />
        ) : (
          <Switch
            onChange={() => {
              let body = {
                two_factor_enabled: "YES",
                landlord_id: parseFloat(localStorage.getItem("id")),
              };
              // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
              fetch(ApiDetails + "pegasus/visionary/authorization/2fa", {
                method: "POST",
                body: JSON.stringify(body),
              })
                .then(async (response) => {
                  if (response.status === 200) {
                    if (JSON.parse(props.landlordDetails.two_fa_set === "NO")) {
                      linkProgramToSkyAuth();
                    }
                    getUserDetails({
                      landlord_id: parseFloat(localStorage.getItem("id")),
                    });
                  } else {
                    props.snackBar({
                      text: "Something Went Wrong. Try Again Later",
                      type: "error",
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                  });
                });
            }}
            {...label}
          />
        )}
      </div>
    </div>
  );
}
