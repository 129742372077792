import React, {useEffect, useState} from "react";
import "./User.css";
import {useLocation, useNavigate} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {
    Autocomplete,
    Avatar,
    Button,
    LinearProgress,
    LinearProgressProps,
    Menu,
    Switch,
    useMediaQuery,
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Backdrop from "@mui/material/Backdrop";
import {BarWave} from "react-cssfx-loading";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {ApiDetails} from "../../dummyData";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import {TextField} from "@material-ui/core";
import Typography from "@mui/material/Typography";
import {
    AttachMoneyOutlined,
    EditOutlined,
    EmailOutlined,
    LocalPhoneOutlined,
    SecurityOutlined,
    ThumbDown,
    ThumbUp,
} from "@material-ui/icons";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {ErrorMessage, Field, Form, Formik} from "formik";
import SendIcon from "@mui/icons-material/Send";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import toast from "react-hot-toast";
import {updatePropertyPaymentDetailsSchema, updatePropertySchema,} from "../../Components/schemas/Schemas";
import adminProfile from "../../Assets/Tenant_avatar.jpg";
import {alpha, styled} from "@mui/material/styles";
import {NumericFormat} from "react-number-format";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import Properties from "../../Components/User components/Properties/Properties";
import Tenants from "../../Components/User components/Tenants/Tenants";
import Settings from "../../Components/User components/Settings/Settings";

const useStyles = makeStyles({
    root: {
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
            fontSize: 16,
            color: "darkblue",
            backgroundColor: "rgb(245,245,245)",
        },
    },
});
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
});

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({theme}) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

function MoneyOffIcon(props: {
    style: { marginRight: string, color: string },
}) {
    return null;
}

export default function User(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const matches = useMediaQuery("(max-width:700px)");

    const navigate = useNavigate();

    let classes = useStyles;
    const location = useLocation();

    const [set, setDetails] = useState(false);

    const [dateFrom, setDateFrom] = React.useState(new Date());

    const handleClose = () => {
        setOpen(false);
        setPaymentModal(false);
        setTransactionModal(false);
    };

    //Modal States
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const openOptions = Boolean(anchorEl);
    const [transactionModal, setTransactionModal] = useState(false);

    const [, setAllUnits] = useState([]);

    // Access the state variable landlord_id
    const landlordId = location.state && location.state.landlord_id;
    const [landlordDetails, setLandlordDetails] = useState({
        first_name: "",
        last_name: "",
        email_address: "",
    });
    const [landlordImage, setLandlordImage] = useState("");
    const [, setInitials] = useState("");

    const [, setFormValues] = useState({
        first_name: "",
        last_name: "",
        email_address: "",
        phone_number: "",
        free_months: "",
        two_fa_set: "",
    });

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email_address: "",
        phone_number: "",
        free_months: "",
        two_fa_set: "",
        subscription_expired: "",
        subscription_amount: "",
        subscription_renewal_date: "",
    });

    useEffect(() => {
        //Fetch landlord details
        fetch(
            ApiDetails + "pegasus/visionary/authorization/admin/get/landlord/details",
            {
                method: "POST",
                body: JSON.stringify({
                    landlord_id: landlordId,
                }),
            }
        )
            .then(async (response) => {
                let details = await response.json();
                if (response.status === 200) {
                    let landlordData = new Map(Object.entries(details));
                    setFormValues({
                        first_name: details.first_name,
                        last_name: details.last_name,
                        email_address: details.email_address,
                        phone_number: details.phone_number,
                        free_months: details.free_months,
                        two_fa_set: details.two_fa_set,
                    });
                    setLandlordDetails(landlordData);
                    setFormData({
                        ...formData,
                        first_name: details.first_name,
                        last_name: details.last_name,
                        email_address: details.email_address,
                        phone_number: details.phone_number,
                        free_months: details.free_months,
                        two_fa_set: details.two_fa_set,
                        two_fa_enabled: details.two_factor_enabled,
                        subscription_expired: details.subscription_expired,
                        subscription_amount: Math.ceil(details.subscription_amount),
                        subscription_renewal_date: details.subscription_renewal_date,
                    });
                    setAmount(Math.ceil(details.subscription_amount));
                    setPhoneNUmber(details.phone_number);
                    setDetails(true);
                    setBackDrop(<></>);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    //New fetch photo
    useEffect(() => {
        try {
            let pic;

            fetch(
                ApiDetails + "pegasus/visionary/authorization/admin/get/landlord/photo",
                {
                    method: "POST",
                    body: JSON.stringify({
                        landlord_id: landlordId,
                    }),
                }
            )
                .then(async (response) => {
                    let data = await response.json();
                    if (response.status === 200) {
                        pic = data.landlord_photo;

                        if (
                            pic.base64 !== "No File Found" &&
                            pic.base64.toString().trim() !== ""
                        ) {
                            // console.log("TRUE")
                            setLandlordImage("data:image/jpeg;base64," + pic.base64);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (e) {
        }
    }, []);

    //Loading Screen
    let [backDrop, setBackDrop] = useState(
        <Backdrop
            sx={{
                backgroundColor: "rgba(0, 31, 63, 0.7)",
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
            onClick={null}
        >
            <BarWave color="#FFA500" width="100px" height="50px" duration="3s"/>
        </Backdrop>
    );

    const [value, setValue] = React.useState("properties");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [transactionLoading, setTransactionLoading] = React.useState(false);

    const [properties, setProperties] = useState([]);

    const handleDelete = (id) => {
        fetch(ApiDetails + "pegasus/visionary/property/delete", {
            method: "POST",
            body: JSON.stringify({property_id: propertyDetails.id}),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    toast.success("Property deleted", {
                        style: {
                            border: "1px solid #green",
                            padding: "16px",
                            color: "green",
                        },
                        icon: <ThumbUp/>,
                        iconTheme: {
                            primary: "#713200",
                            secondary: "#FFFAEE",
                        },
                    });
                    window.location.reload()
                    handleClose();
                } else {
                    toast.success("Error occurred. Try again later", {
                        style: {
                            border: "1px solid #red",
                            padding: "16px",
                            color: "red",
                        },
                        icon: <ThumbDown/>,
                        iconTheme: {
                            primary: "#713200",
                            secondary: "#FFFAEE",
                        },
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };

    const [paymentModal, setPaymentModal] = useState(false);
    const [penalties, setPenalties] = useState("");
    const [finished, setFinished] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [unitsModal, setUnitsModal] = useState(false);
    const [messageModal, setMessageModal] = useState(false);
    const [unitReportModal, setUnitReportModal] = useState(false);
    const [billTenantsModal, setBillTenantsModal] = useState(false);
    const [agencyTokenModal, setAgencyTokenModal] = useState(false);
    const [editPaymentDetailsModal, setEditPaymentDetailsModal] = useState(false);
    const [accountType, setAccountType] = useState({
        label: "Buy Goods",
        value: "CustomerBuyGoodsOnline",
    });

    const [tenantLoading, setTenantLoading] = useState(false);

    const getAllUnits = (id) => {
        fetch(ApiDetails + "pegasus/visionary/property/getAllUnits", {
            method: "POST",
            body: JSON.stringify({property_id: id}),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setAllUnits(data);
                    props.snackBar({
                        text: "Property Units Retreived Successful",
                        type: "success",
                    });
                } else if (response.status === 401) {
                    props.snackBar({
                        text: "Maximum concurrent logins reached",
                        type: "warning",
                    });
                } else if (response.status === 400) {
                    props.snackBar({
                        text: "Invalid Credentials Provided.",
                        type: "error",
                    });
                } else if (response.status === 500) {
                    if (data["Message"].includes("duplicate key")) {
                        if (data["Message"].includes("owners_email_address_uindex")) {
                            props.snackBar({
                                text: "Email Address Already Exists",
                                type: "error",
                            });
                            return;
                        }
                    }
                    props.snackBar({
                        text: "Something went wrong. Please try again later.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };

    function LinearProgressWithLabel(
        props: LinearProgressProps & { value: number }
    ) {
        return (
            <Box sx={{display: "flex", alignItems: "center"}}>
                <Box sx={{width: "100%", mr: 1}}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{minWidth: 35}}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }

    const [propertyDetails, setPropertyDetails] = useState({
        id: "",
        name: "",
    });

    const label = {inputProps: {"aria-label": "Switch demo"}};

    let [tenantInfo, setTenantInfo] = React.useState({
        tenant_id: "",
        name: "",
        last_name: "",
        unit: "",
        property_id: "",
        account_number: "",
    });
    let initialValues4 = {
        id: "",
        first_name: "",
        date: "",
        amount: "",
    };

    const addTransaction = (values) => {
        setTransactionLoading(true);
        /*
                "TransactionType": "Pay Bill",
                        "TransID": "QIA3PD6BCB",
                        "TransTime": "20220910102100",
                        "TransAmount": "50.00",
                        "BusinessShortCode": "4103275",
                        "BillRefNumber": "M1",
                        "InvoiceNumber": "",
                        "OrgAccountBalance": "170.00",
                        "ThirdPartyTransID": "",
                        "MSISDN": "2547 ***** 249",
                        "FirstName": "DEREK"
                */
        let body = {
            TransactionType: "Pay Bill",
            TransID: "**********",
            // TransTime: "20220910102100",
            TransTime: dateFrom,
            TransAmount: values.amount,
            BusinessShortCode: "4103275",
            BillRefNumber: tenantInfo.account_number,
            InvoiceNumber: "",
            OrgAccountBalance: "170.00",
            ThirdPartyTransID: "",
            MSISDN: "2547 ***** 249",
            FirstName: tenantInfo.name,
            tenant_id: tenantInfo.id,
            landlord_id: landlordId,
        };
        if (values.amount === "") {
            props.snackBar({text: "Amount Can't Be Empty", type: "error"});
            return;
        }

        fetch(ApiDetails + "pegasus/visionary/payments/", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    props.snackBar({
                        text: "Transaction Added",
                        type: "success",
                    });
                    setBackDrop(
                        <Backdrop
                            sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                            open={true}
                            onClick={null}
                        >
                            <BarWave
                                color="#FFA500"
                                width="100px"
                                height="50px"
                                duration="3s"
                            />
                        </Backdrop>
                    );
                    handleClose();
                } else {
                    handleClose();
                    props.snackBar({
                        text: "Something Went Wrong, Please Try Again Later ",
                        type: "error",
                    });
                }
                setTransactionLoading(false);
            })
            .catch((err) => {
                console.log(err);
                handleClose();
                props.snackBar({
                    text: "Something Went Wrong, Please Try Again Later ",
                    type: "error",
                });
            });
    };

    const [modalOpen, setModalOpen] = useState(false);
    const MAX_IMAGE_SIZE_BYTES = 5 * 1024 * 1024; // 5 MB (adjust as needed)

    const updateProfileImage = (file) => {
        let formData = new FormData();
        formData.append("landlord_id", JSON.stringify({landlord_id: landlordId}));
        formData.append("landlord_photo", file);

        //Make API call
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(
            ApiDetails +
            "pegasus/visionary/authorization/settings/update/landlord/image",
            {
                method: "POST",
                body: formData,
            }
        )
            .then(async (response) => {
                await response.json();
                if (response.status === 200) {
                    props.snackBar({
                        text: "Photo updated",
                        type: "success",
                    });
                } else {
                    props.snackBar({
                        text: "Failed uploading photo.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };

    const [profPicLoading, setProfPicLoading] = useState(false);
    const handleDrop = async (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];

        if (file) {
            if (file.size <= MAX_IMAGE_SIZE_BYTES) {
                setProfPicLoading(true);

                const reader = new FileReader();
                reader.onload = (e) => {
                    setLandlordImage(e.target.result);
                };
                reader.readAsDataURL(file);
                await updateProfileImage(file);
                // setProfPicLoading(false);
            } else {
                alert("Selected image is too large. Please choose a smaller image.");
            }
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleMouseEnter = () => {
        // Add your fade effect logic or any other styling changes on mouse enter
    };

    const handleMouseLeave = () => {
        // Add your fade effect logic or any other styling changes on mouse leave
    };

    const handleImageClick = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const updatePenaltyDetails = (values, id) => {
        fetch(ApiDetails + "pegasus/visionary/property/penalty/details", {
            method: "POST",
            body: JSON.stringify({...values, property_id: id}),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    handleClose();
                    props.snackBar({
                        text: "Property Details Updated Successfully",
                        type: "success",
                    });
                } else if (response.status === 401) {
                    props.snackBar({
                        text: "Maximum concurrent logins reached",
                        type: "warning",
                    });
                } else if (response.status === 500) {
                    props.snackBar({
                        text: "Something went wrong. Please try again later.",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };

    const updateProperty = (values) => {
    };

    const updatePropertyPaymentDetails = (values) => {
        if (accountType === null) {
            props.snackBar({
                text: "Kindly select account type",
                type: "error",
            });
            return;
        }

        let body = {
            ...values,
            transaction_type: accountType,
        };
        fetch(ApiDetails + "pegasus/visionary/property/update/payment/details", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    handleClose();
                    window.location.reload(false);
                    props.snackBar({
                        text: "Payment Details Updated",
                        type: "success",
                    });
                } else {
                    props.snackBar({
                        text: "An error occurred",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later",
                    type: "error",
                });
            });
    };

    const initialValues3 = {
        // minimum_days_to_vacate: property.minimum_days_to_vacate,
    };

    const initialValues5 = {};

    const initialValues6 = {};


    const [amount, setAmount] = useState();
    let [phoneNumber, setPhoneNUmber] = useState();

    let basicSchema = yup.object().shape({
        phone_number: yup.string().phone("KE", true).required("Required"),
        amount: yup.number().min(1, "Amount must be more than 1 KSH"),
    });
    const processPayment = async (values) => {
        setTenantLoading(true);

        const kenyanPhoneNumberRegex =
            /^(?:\+254|0)(?:(?:(7([0-2]|[4-9]))|1([0-2]))[0-9]{6}|((3([0-2]|[4-9]))|4([0-5]|[7-9]))[0-9]{6}|5([0-6])[0-9]{6}|6([8-9])[0-9]{6})$/;

        const phoneSchema = yup
            .string()
            .matches(kenyanPhoneNumberRegex, "Invalid Kenyan phone number");

        let breakExecution = false;

        phoneSchema.isValid(phoneNumber).then((isValid) => {
            if (!isValid) breakExecution = true;
        });

        if (breakExecution) {
            setTenantLoading(false);
            props.snackBar({
                text: "Enter valid phone number",
                type: "error",
            });
            return;
        }

        if (phoneNumber.toString().startsWith("0")) {
            phoneNumber = "254" + phoneNumber.toString().substring(1);
        } else if (
            phoneNumber.toString().startsWith("1") ||
            phoneNumber.toString().startsWith("7")
        ) {
            phoneNumber = "254" + phoneNumber.toString();
        }

        let body = {
            BusinessShortCode: "6122464",
            Password: "",
            Timestamp: "",
            TransactionType: "CustomerBuyGoodsOnline",
            Amount: amount,
            PartyA: phoneNumber, // PartyB: "6122464",
            PartyB: "5726811",
            PhoneNumber: phoneNumber,
            CallBackURL:
                "https://api.pegasus-pms.co.ke/api/" +
                "pegasus/visionary/payments/subscription",
            AccountReference: "ITORAMA SOLUTIONS",
            TransactionDesc: "Subscription payment",
            landlord_id: landlordId,
        };

        console.log(body);
        fetch(ApiDetails + "pegasus/visionary/mpesa/landlord/StkPush", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setTenantLoading(false);
                    props.snackBar({
                        text: "Kindly wait For mpesa prompt on your phone",
                        type: "success",
                    });
                } else if (response.status === 403) {
                    setTenantLoading(false);
                    props.snackBar({
                        text: new Map(Object.entries(data)).get("Message"),
                        type: "error",
                    });
                }
                handleClose();
            })
            .catch((err) => {
                console.log(err);
                handleClose();
                setTenantLoading(false);
                props.snackBar({
                    text: "Payment Not Successful",
                    type: "error",
                });
            });
    };

    return (
        <div
            style={{
                padding: "20px",
            }}
        >
            {backDrop}
            <Dialog
                open={paymentModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    style={{
                        color: "#398DD2",
                    }}
                >
                    Renew subscription
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <div>
                            <p>Enter number to be prompted to make payment.</p>
                            <TextField
                                as={TextField}
                                name="phone_number"
                                label="Phone Number"
                                required
                                value={phoneNumber}
                                variant="outlined"
                                style={{marginTop: "10px", width: "100%"}}
                                onChange={(event) => setPhoneNUmber(event.target.value)}
                                InputLabelProps={{shrink: true}}
                            />
                            <TextField
                                as={TextField}
                                name="amount"
                                label="Amount"
                                type="number"
                                required
                                value={amount}
                                variant="outlined"
                                InputLabelProps={{shrink: true}}
                                style={{marginTop: "10px", width: "100%"}}
                                onChange={(event) => setAmount(event.target.value)}
                            />

                            <br/>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "end",
                                    marginTop: "20px",
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        setTenantLoading(false);
                                        handleClose();
                                    }}
                                >
                                    <span style={{color: "red"}}>Cancel</span>
                                </Button>
                                <LoadingButton
                                    type={"submit"}
                                    size="small"
                                    onClick={processPayment}
                                    loading={tenantLoading}
                                    startIcon={<SendIcon/>}
                                    style={{color: "green"}}
                                    loadingPosition="start"
                                >
                                    Submit
                                </LoadingButton>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            <Dialog
                maxWidth={"md"}
                open={editModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    style={{
                        width: "90%",
                    }}
                >
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Edit Property
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        border: "2px solid blue",
                        borderRadius: "5px",
                        padding: "10px",
                        margin: "5px",
                    }}
                >
                    {set ? (
                        <Formik
                            initialValues={initialValues3}
                            validationSchema={updatePropertySchema}
                            onSubmit={updateProperty}
                        >
                            {(props) => (
                                <Form noValidate>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Field
                                            as={TextField}
                                            name="property_name"
                                            label="Property Name"
                                            variant="outlined"
                                            error={
                                                props.errors.property_name &&
                                                props.touched.property_name
                                            }
                                            helperText={<ErrorMessage name="property_name"/>}
                                            required
                                            style={{marginTop: "10px", width: "49%"}}
                                        />
                                        <Field
                                            as={TextField}
                                            name="location"
                                            label="Property Location"
                                            error={props.errors.location && props.touched.location}
                                            helperText={<ErrorMessage name="location"/>}
                                            required
                                            variant="outlined"
                                            style={{marginTop: "10px", width: "49%"}}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Field
                                            as={TextField}
                                            name="query_number"
                                            label="Query Number"
                                            error={
                                                props.errors.query_number && props.touched.query_number
                                            }
                                            helperText={<ErrorMessage name="query_number"/>}
                                            required
                                            variant="outlined"
                                            style={{marginTop: "10px", width: "49%"}}
                                        />
                                        <Field
                                            as={TextField}
                                            name="payment_details"
                                            label="Payment Details"
                                            error={
                                                props.errors.payment_details &&
                                                props.touched.payment_details
                                            }
                                            helperText={<ErrorMessage name="payment_details"/>}
                                            required
                                            variant="outlined"
                                            style={{marginTop: "10px", width: "49%"}}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Field
                                            as={TextField}
                                            name="minimum_water_bill"
                                            label="Min Water Bill"
                                            error={
                                                props.errors.minimum_water_bill &&
                                                props.touched.minimum_water_bill
                                            }
                                            helperText={<ErrorMessage name="minimum_water_bill"/>}
                                            required
                                            variant="outlined"
                                            style={{marginTop: "10px", width: "49%"}}
                                        />
                                        <Field
                                            as={TextField}
                                            name="water_bill"
                                            label="Water Bill"
                                            error={
                                                props.errors.water_bill && props.touched.water_bill
                                            }
                                            helperText={<ErrorMessage name="water_bill"/>}
                                            required
                                            variant="outlined"
                                            style={{marginTop: "10px", width: "49%"}}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Field
                                            as={TextField}
                                            name="minimum_electricity_bill"
                                            label="Min Electricity Bill"
                                            error={
                                                props.errors.minimum_electricity_bill &&
                                                props.touched.minimum_electricity_bill
                                            }
                                            helperText={
                                                <ErrorMessage name="minimum_electricity_bill"/>
                                            }
                                            required
                                            variant="outlined"
                                            style={{marginTop: "10px", width: "49%"}}
                                        />
                                        <Field
                                            as={TextField}
                                            name="electricity_bill"
                                            label="Electricity Bill"
                                            error={
                                                props.errors.electricity_bill &&
                                                props.touched.electricity_bill
                                            }
                                            helperText={<ErrorMessage name="electricity_bill"/>}
                                            required
                                            variant="outlined"
                                            style={{marginTop: "10px", width: "49%"}}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Field
                                            as={TextField}
                                            name="other_charges"
                                            label="Other Charges"
                                            type={"number"}
                                            error={
                                                props.errors.other_charges &&
                                                props.touched.other_charges
                                            }
                                            helperText={<ErrorMessage name="other_charges"/>}
                                            required
                                            variant="outlined"
                                            style={{marginTop: "10px", width: "49%"}}
                                        />
                                        <Field
                                            as={TextField}
                                            name="minimum_days_to_vacate"
                                            label="Minimum months before vacating"
                                            type={"number"}
                                            error={
                                                props.errors.minimum_days_to_vacate &&
                                                props.touched.minimum_days_to_vacate
                                            }
                                            helperText={
                                                <ErrorMessage name="minimum_days_to_vacate"/>
                                            }
                                            required
                                            variant="outlined"
                                            style={{marginTop: "10px", width: "49%"}}
                                        />
                                    </div>

                                    <br/>

                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "end",
                                        }}
                                    >
                                        <Button type="submit">
                                            <span style={{color: "green"}}>Save</span>
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    ) : (
                        <></>
                    )}
                    <hr
                        style={{
                            color: "blue",
                            marginBottom: "5px",
                        }}
                    />
                    <div>
                        {finished ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>
                  <span
                      style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#398DD2",
                      }}
                  >
                    Enable Penalties
                  </span>
                                    <br/>
                                    <Typography
                                        style={{
                                            color: "#959EAD",
                                            width: "80%",
                                        }}
                                    >
                                        All payments done past the specified date shall incur the
                                        specified percentage penalty
                                    </Typography>
                                </div>
                                {penalties === "YES" ? (
                                    <Switch
                                        onChange={() => {
                                            let body = {};
                                            fetch(ApiDetails + "pegasus/visionary/property/penalty", {
                                                method: "POST",
                                                body: JSON.stringify(body),
                                            })
                                                .then(async (response) => {
                                                    if (response.status === 200) {
                                                        setPenalties("NOT");
                                                        props.snackBar({
                                                            text: "Changes have Been Saved",
                                                            type: "success",
                                                        });
                                                    } else {
                                                        props.snackBar({
                                                            text: "Something Went Wrong. Try Again Later",
                                                            type: "error",
                                                        });
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.log(err);
                                                    props.snackBar({
                                                        text: "Something Went Wrong. Try Again Later",
                                                        type: "error",
                                                    });
                                                });
                                        }}
                                        {...label}
                                        defaultChecked
                                    />
                                ) : (
                                    <Switch
                                        onChange={() => {
                                            let body = {};
                                            fetch(ApiDetails + "pegasus/visionary/property/penalty", {
                                                method: "POST",
                                                body: JSON.stringify(body),
                                            })
                                                .then(async (response) => {
                                                    if (response.status === 200) {
                                                        setPenalties("YES");
                                                        props.snackBar({
                                                            text: "Changes have Been Saved",
                                                            type: "success",
                                                        });
                                                    } else {
                                                        props.snackBar({
                                                            text: "Something Went Wrong. Try Again Later",
                                                            type: "error",
                                                        });
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.log(err);
                                                    props.snackBar({
                                                        text: "Something Went Wrong. Try Again Later",
                                                        type: "error",
                                                    });
                                                });
                                        }}
                                        {...label}
                                    />
                                )}
                            </div>
                        ) : (
                            <></>
                        )}
                        <br/>
                        {finished ? (
                            <Formik
                                initialValues={initialValues5}
                                onSubmit={updatePenaltyDetails}
                            >
                                {(props) => (
                                    <Form noValidate>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Field
                                                as={TextField}
                                                name={"reminder_date"}
                                                label="Reminder Date"
                                                variant="outlined"
                                                required
                                                style={{marginTop: "10px", width: "30%"}}
                                            />
                                            <Field
                                                as={TextField}
                                                name="penalty_date"
                                                label="Penalty Date"
                                                required
                                                variant="outlined"
                                                style={{marginTop: "10px", width: "30%"}}
                                            />
                                            <Field
                                                as={TextField}
                                                name={"penalty_percentage"}
                                                label="Percentage Penalty"
                                                required
                                                variant="outlined"
                                                style={{marginTop: "10px", width: "30%"}}
                                            />
                                        </div>
                                        <br/>
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "end",
                                            }}
                                        >
                                            <Button type={"submit"}>
                                                <span style={{color: "green"}}>Save</span>
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        ) : (
                            <></>
                        )}

                        {/*<br />*/}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Cancel</span>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                maxWidth="xl"
                open={editPaymentDetailsModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    style={{
                        width: "90%",
                    }}
                >
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Payment details
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                        margin: "5px",
                    }}
                >
                    {finished ? (
                        <div>
                            <Formik
                                initialValues={initialValues6}
                                validationSchema={updatePropertyPaymentDetailsSchema}
                                onSubmit={updatePropertyPaymentDetails}
                            >
                                {(props) => (
                                    <Form noValidate>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "80vw",
                                            }}
                                        >
                                            <Field
                                                as={TextField}
                                                name="ho_number"
                                                label="HO Number"
                                                variant="outlined"
                                                error={
                                                    props.errors.ho_number && props.touched.ho_number
                                                }
                                                helperText={<ErrorMessage name="ho_number"/>}
                                                required
                                                style={{marginTop: "10px", width: "49%"}}
                                            />
                                            <Field
                                                as={TextField}
                                                name="till_number"
                                                label="Till / Paybill number"
                                                error={
                                                    props.errors.till_number && props.touched.till_number
                                                }
                                                helperText={<ErrorMessage name="till_number"/>}
                                                required
                                                variant="outlined"
                                                style={{marginTop: "10px", width: "49%"}}
                                            />
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "80vw",
                                            }}
                                        >
                                            <Field
                                                as={TextField}
                                                name="short_code"
                                                label="Short Code"
                                                error={
                                                    props.errors.short_code && props.touched.short_code
                                                }
                                                helperText={<ErrorMessage name="short_code"/>}
                                                required
                                                variant="outlined"
                                                style={{marginTop: "10px", width: "49%"}}
                                            />
                                            <Field
                                                as={TextField}
                                                name="pass_key"
                                                label="Pass Key"
                                                error={props.errors.pass_key && props.touched.pass_key}
                                                helperText={<ErrorMessage name="pass_key"/>}
                                                required
                                                variant="outlined"
                                                style={{marginTop: "10px", width: "49%"}}
                                            />
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "80vw",
                                            }}
                                        >
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={[
                                                    {
                                                        label: "Pay Bill",
                                                        value: "CustomerPayBillOnline",
                                                    },
                                                    {
                                                        label: "Buy Goods",
                                                        value: "CustomerBuyGoodsOnline",
                                                    },
                                                ]}
                                                disabled={true}
                                                value={{
                                                    label: "Buy Goods",
                                                    value: "CustomerBuyGoodsOnline",
                                                }}
                                                style={{marginTop: "10px", width: "49%"}}
                                                onChange={(e, value) => {
                                                    setAccountType(value.value);
                                                    console.log(value);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="Select Account Type "
                                                        variant="outlined"
                                                        name="property"
                                                        {...params}
                                                    />
                                                )}
                                            />
                                            <Field
                                                as={TextField}
                                                name="consumer_secret"
                                                label="Consumer Secret"
                                                error={
                                                    props.errors.consumer_secret &&
                                                    props.touched.consumer_secret
                                                }
                                                helperText={<ErrorMessage name="consumer_secret"/>}
                                                required
                                                variant="outlined"
                                                style={{marginTop: "10px", width: "49%"}}
                                            />
                                        </div>

                                        <div></div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "right",
                                                width: "80vw",
                                            }}
                                        >
                                            <Button type={"submit"}>
                                                <span style={{color: "green"}}>Submit</span>
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    ) : (
                        <></>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Cancel</span>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={transactionModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Payment Details
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <Formik initialValues={initialValues4} onSubmit={addTransaction}>
                        {() => (
                            <Form noValidate>
                                <Field
                                    as={TextField}
                                    name="first_name"
                                    label="Tenant Name"
                                    value={tenantInfo.name + " " + tenantInfo.last_name}
                                    variant="outlined"
                                    fullwidth
                                    required
                                    disabled={true}
                                    style={{marginTop: "8px", width: "100%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="amount"
                                    label="Amount"
                                    type="number"
                                    fullwidth
                                    required
                                    variant="outlined"
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        width: "100%",
                                    }}
                                />
                                <div
                                    style={{
                                        marginRight: "10px",
                                    }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            disableFuture
                                            id="outlined-basic"
                                            variant="outlined"
                                            label="Date "
                                            openTo="year"
                                            views={["year", "month", "day"]}
                                            value={dateFrom}
                                            onChange={(newValue) => {
                                                setDateFrom(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "end",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Button onClick={handleClose}>
                                        <span style={{color: "red"}}>Cancel</span>
                                    </Button>
                                    <LoadingButton
                                        size="small"
                                        type={"submit"}
                                        loading={transactionLoading}
                                        startIcon={<SendIcon/>}
                                        style={{color: "green"}}
                                        loadingPosition="start"
                                    >
                                        Agree
                                    </LoadingButton>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{color: "red"}}>
                    Delete {propertyDetails.name}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography>
                            Deleting a property will completely remove it and its tenants from
                            the system
                        </Typography>
                        <Typography>
                            Are you sure you want to delete this property ?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Disagree</span>
                    </Button>
                    <Button onClick={handleDelete}>
            <span style={{color: "green"}} onClick={() => {
            }}>
              Agree
            </span>
                    </Button>
                </DialogActions>
            </Dialog>

            <div
                style={{
                    width: "100%",
                    display: "flex",
                }}
            >
                <div>
                    <div
                        style={{
                            width: "220px",
                            height: "220px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            marginRight: "10px",
                            border: "2px dashed #ccc",
                            position: "relative",
                        }}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={handleImageClick}
                    >
                        {profPicLoading && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            >
                                <CircularProgress/>
                            </div>
                        )}

                        <div>
                            <Avatar
                                style={{
                                    width: "220px",
                                    height: "220px",
                                }}
                                alt="Admin profile picture"
                                src={landlordImage !== "" ? landlordImage : adminProfile}
                            />
                        </div>
                    </div>

                    {modalOpen && (
                        <div
                            style={{
                                position: "fixed",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: 1000,
                                transition: "opacity 0.3s ease-in-out",
                            }}
                            onClick={handleCloseModal}
                        >
                            <div
                                style={{
                                    opacity: modalOpen ? 1 : 0,
                                }}
                                className={"pictureModal"}
                            >
                                <img
                                    src={landlordImage === "" ? adminProfile : landlordImage}
                                    alt="Server"
                                    style={{width: "100%", height: "100%", objectFit: "cover"}}
                                />
                            </div>
                        </div>
                    )}
                </div>

                <div
                    className="featuredItemBox"
                    style={{
                        display: "flex",
                    }}
                >
                    <div
                    style={{
                        width:"100%"
                    }}>
                        <div
                            style={{
                                fontFamily: "'DM Serif Display', serif",
                                letterSpacing: 1,
                            }}
                        >
                            <div
                                style={{
                                    color: "#9A005A",
                                    letterSpacing: 2,
                                    marginBottom: "20px",
                                    textTransform: "uppercase",
                                    fontFamily: "'DM Serif Display', serif",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <h2>{formData.first_name + " " + formData.last_name}</h2>

                                <Tooltip
                                    title="Edit details"
                                    style={{
                                        margin: "10px",
                                    }}
                                >
                                    <IconButton>
                                        <EditOutlined
                                            onClick={()=>{
                                                setValue("account settings")
                                            }}
                                            style={{
                                                color: "blue",
                                                margin: "10px",
                                                fontSize: "20px",
                                                cursor: "pointer",
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </div>

                            <div
                                style={{
                                    fontFamily: "'Roboto', sans-serif",
                                    paddingLeft: "20px",
                                }}
                            >
                                <div
                                    style={{
                                        paddingBottom: "10px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                  <span
                      style={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                      }}
                  >
                    <LocalPhoneOutlined
                        style={{
                            color: "#00ADA5",
                            fontSize:"20px",
                            marginRight: "10px",
                        }}
                    />
                    Phone:
                  </span>
                                    <span style={{flex: 1}}>
                    <NumericFormat
                        value={formData.phone_number}
                        displayType={"text"}
                        format="+254 (###) ###-####"
                    />
                  </span>
                                </div>
                                <div
                                    style={{
                                        paddingBottom: "10px",
                                        display: "flex",
                                        justifyItems: "space-between",
                                    }}
                                >
                  <span
                      style={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                      }}
                  >
                    <EmailOutlined
                        style={{
                            color: "#00ADA5",
                            fontSize:"20px",
                            marginRight: "10px",
                        }}
                    />
                   Email:
                  </span>

                                    <span style={{flex: 1}}>{formData.email_address}</span>
                                </div>

                                <div
                                    style={{
                                        paddingBottom: "10px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                  <span
                      style={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                      }}
                  >
                    <SecurityOutlined
                        style={{
                            color: "#00ADA5",
                            fontSize:"20px",
                            marginRight: "10px",
                        }}
                    />
                    2FA Set:
                  </span>
                                    <span style={{flex: 1}}>{formData.two_fa_set}</span>
                                </div>

                                <div
                                    style={{
                                        paddingBottom: "10px",
                                        display: "flex",
                                        justifyItems: "space-between",
                                    }}
                                >
                  <span
                      style={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                      }}
                  >
                    <SecurityOutlined
                        style={{
                            color: "#00ADA5",
                            fontSize:"20px",
                            marginRight: "10px",
                        }}
                    />
                    2FA Enabled:
                  </span>
                                    <span style={{flex: 1}}>{formData.two_fa_enabled}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        flex: "1",
                        marginRight: "10px",
                        marginLeft: "10px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                    }}
                >
                    <div className="featuredItemBox">
            <span
                style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    paddingBottom: "10px",
                }}
            >
              <AttachMoneyOutlined
                  style={{color: "#398DD2", marginRight: "5px"}}
              />
              <div
                  style={{
                      color: "#9A005A",
                      letterSpacing: 2,
                      textTransform: "uppercase",
                      fontFamily: "'DM Serif Display', serif",
                  }}
              >
                <h4>Subscription details</h4>
              </div>
            </span>
                        <div
                            style={{
                                fontFamily: "'Roboto', sans-serif",
                                paddingLeft: "20px",
                            }}
                            className="featuredMoneyContainer"
                        >
              <span className="featuredMoney">
                <div
                    style={{
                        paddingBottom: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                  <span
                      style={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                      }}
                  >
                    Subscription status:
                  </span>
                  <span style={{flex: 1}}>
                    {!formData.subscription_expired ? (
                        <div
                            style={{
                                margin: "auto",
                                backgroundColor:
                                    "#d2ffd2" /* Green background color */,
                                borderRadius: "10px" /* Rounded corners */,
                                padding: "5px" /* Padding for inner content */,
                                color: "green" /* Text color */,
                                fontSize: "12px" /* Font size */,
                                textAlign: "center" /* Center text horizontally */,
                            }}
                        >
                            ACTIVE
                        </div>
                    ) : (
                        <div
                            style={{
                                margin: "auto",
                                backgroundColor:
                                    "#ffd7d7" /* Green background color */,
                                borderRadius: "10px" /* Rounded corners */,
                                padding: "5px" /* Padding for inner content */,
                                color: "red" /* Text color */,
                                fontSize: "12px" /* Font size */,
                                textAlign: "center" /* Center text horizontally */,
                            }}
                        >
                            EXPIRED
                        </div>
                    )}
                  </span>
                </div>

                <div
                    style={{
                        paddingBottom: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                  <span
                      style={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                      }}
                  >
                    Subscription amount:
                  </span>
                  <span style={{flex: 1}}>
                    {formData.subscription_amount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "KES",
                        minimumFractionDigits: 2,
                    })}
                  </span>
                </div>

                <div
                    style={{
                        paddingBottom: "10px",
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                    }}
                >
                  <span
                      style={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                      }}
                  >
                    Date subscribed:
                  </span>
                  <span style={{flex: 1}}>
                    {formData.subscription_renewal_date}
                  </span>
                </div>
              </span>
                        </div>

                        <Button
                            style={{
                                marginTop: "10px",
                                marginLeft: "20px",
                            }}
                            onClick={(event) => {
                                setPaymentModal(true);
                            }}
                            variant="contained"
                        >
                            Subscribe
                        </Button>
                    </div>
                </div>
            </div>

            <Box sx={{width: "100%", marginTop: "10px"}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                >
                    <Tab value="properties" label="Properties"/>
                    <Tab value="tenants" label="Tenants"/>
                    <Tab value="account settings" label="Account settings"/>
                </Tabs>
            </Box>

            <div
                style={{
                    height: "45vh",
                    // overflow:"scroll"
                }}
            >

                {value === "properties" &&
                    <Properties
                        landlord_id={landlordId}/>}

                {value === "tenants" &&
                    <Tenants
                        landlord_id={landlordId}
                        setOpen={() => setOpen(!open)}
                        setTenantInfo={(info) => setTenantInfo(info)}
                        transactionModal={() => setTransactionModal(!transactionModal)}/>}

                {value === "account settings" &&
                    <Settings
                        snackBar={props.snackBar}
                        landlord_id={landlordId}
                        initialValues={landlordDetails}
                    />
                }

            </div>
        </div>
    );
}
