import React, { useEffect, useState } from "react";
import "./SideBar.css";
import { Menu, MenuItem, Sidebar, useProSidebar } from "react-pro-sidebar";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { Link } from "react-router-dom";
// import logo from "../../Assets/react-logo-no-background.svg";
// import logo from '../../Assets/Admin_profile-removebg-preview.png';
import logo from "../../Assets/Admin_profile1-removebg-preview.png";
import {
  BackupOutlined,
  ConstructionOutlined,
  GroupsOutlined,
  LogoutOutlined,
} from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import {
  AssignmentOutlined,
  FeedbackOutlined,
  SettingsOutlined,
} from "@material-ui/icons";

export default function TestSidebar({ yourProp }) {
  const matches = useMediaQuery("(max-width:800px)");

  const [logoDisplay, setLogoDisplay] = useState(true);
  const [forceUpdate, setForceUpdate] = useState(false);
  const { collapseSidebar } = useProSidebar();

  useEffect(() => {
    if (matches) {
      collapseSidebar();
      setLogoDisplay(false);
    }
  }, [matches, forceUpdate]);

  const logout = () => {
    // 👇️ toggle class on click
    localStorage.clear();
    // props.fn();
    window.location.href = "/";
  };

  return {
    setForceUpdate,
    sideBar: (
      <div
        style={{
          display:
            window.location.pathname === "/" ||
            window.location.pathname === "/signup"
              ? "none"
              : "",
          // color: "#6DFACD",
        }}
      >
        <div id="app">
          <Sidebar style={{ height: "100vh", position: "sticky", top: "0" }}>
            <Menu
              menuItemStyles={{
                button: ({ level, active, disabled }) => {
                  // only apply styles on first level elements of the tree
                  // if (level === 0)
                  if (level === 0) {
                    return {
                      color: active ? "#FFFFFF" : undefined,
                      backgroundColor: active ? "#3D8B84" : undefined,
                    };
                  } else {
                    return {
                      color: active ? "#FFFFFF" : undefined,
                      backgroundColor: active ? "#67BC89" : undefined,
                    };
                  }
                },
              }}
            >
              <MenuItem
                icon={<MenuOutlinedIcon />}
                onClick={() => {
                  if (!matches) {
                    setLogoDisplay(!logoDisplay);
                  }
                  collapseSidebar();
                }}
                style={{ textAlign: "center" }}
              >
                {" "}
                {/*<h2>Hide</h2>*/}
              </MenuItem>

              <div
                className="topLeft"
                style={{
                  textAlign: "center",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "200px",
                    display: logoDisplay ? "" : "none",
                  }}
                  src={logo}
                  alt=""
                />
              </div>

              {/*<MenuItem*/}
              {/*    active={window.location.pathname === "/home"}*/}
              {/*    component={<Link to="/home"/>}*/}
              {/*    icon={<DashboardOutlined/>}*/}
              {/*>*/}
              {/*    Dashboard*/}
              {/*</MenuItem>*/}

              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>
                  <MenuItem
                    active={window.location.pathname === "/landlords"}
                    component={<Link to={"/landlords"} />}
                    icon={
                      <GroupsOutlined
                        style={{
                          color: "#9A005A",
                        }}
                      />
                    }
                  >
                    Landlords
                  </MenuItem>

                  <MenuItem
                    active={window.location.pathname === "/backup"}
                    component={<Link to={"/backup"} />}
                    icon={
                      <BackupOutlined
                        style={{
                          color: "#9A005A",
                        }}
                      />
                    }
                  >
                    Backup
                  </MenuItem>

                  <MenuItem
                    active={window.location.pathname === "/test"}
                    component={<Link to={"/test"} />}
                    icon={
                      <ConstructionOutlined
                        style={{
                          color: "#9A005A",
                        }}
                      />
                    }
                  >
                    Test Page
                  </MenuItem>

                  <MenuItem
                    active={window.location.pathname === "/logs"}
                    component={<Link to={"/logs"} />}
                    icon={
                      <AssignmentOutlined
                        style={{
                          color: "#9A005A",
                        }}
                      />
                    }
                  >
                    System logs
                  </MenuItem>
                </div>

                <div>
                  <MenuItem
                    active={window.location.pathname === "/feedback"}
                    // component={<Link to={"/logs"} />}
                    onClick={yourProp}
                    icon={
                      <FeedbackOutlined
                        style={{
                          color: "#9A005A",
                        }}
                      />
                    }
                  >
                    Feedback
                  </MenuItem>

                  <MenuItem
                    active={window.location.pathname === "/settings"}
                    component={<Link to={"/settings"} />}
                    icon={
                      <SettingsOutlined
                        style={{
                          color: "#9A005A",
                        }}
                      />
                    }
                  >
                    Settings
                  </MenuItem>

                  <MenuItem
                    onClick={logout}
                    icon={
                      <LogoutOutlined
                        style={{
                          color: "red",
                        }}
                      />
                    }
                  >
                    Logout
                  </MenuItem>
                </div>
              </div>
            </Menu>
          </Sidebar>
        </div>
      </div>
    ),
  };
}
